<template>
  <div class="body">
    <description />
    <our-program />
  </div>
</template>

<script>
export default {
  components: {
    description: () => import("@containers/Compensation/description.vue"),
    "our-program": () => import("@containers/Compensation/our-program.vue"),
  },
};
</script>

<style scoped lang="scss">
.body {
  background: #141822;
}
</style>
