<template>
  <div class="details-partner">
    <div class="details-partner__header">
      <p class="title">{{ $t('menu-items.our-partners') }}</p>
    </div>
    <div class="details-partner__content">
      <div class="row">
        <div class="col-sm-12 col-md-6 col-lg-6">
          <div class="img-arrow">
            <img
              src="/img/arrow-back.svg"
              alt="arrow-back"
              class="arrow-back"
              @click="$router.push('/our-partners')"
            >
            <span class="name">
              {{ detailBusiness.BusinessName }}
            </span>
          </div>
          <div class="content" v-html="detailBusiness.BusinessDescription" />
        </div>
        <div class="col-sm-12 col-md-6 col-lg-6 map">
          <template v-if="detailBusiness.BusinessLatitude !== '0' && detailBusiness.BusinessLongitude !== '0'">
            <Map :location="{lat: detailBusiness.BusinessLatitude, lng: detailBusiness.BusinessLongitude}" />
          </template>
          <template v-else>
            <Map />
          </template>
          <div class="address">
            <p class="title">{{ detailBusiness.BusinessName }}</p>
            <p>
              <i class="fa fa-phone"></i>
              Mobile {{ detailBusiness.PhoneNumber }}
            </p>
            <p>
              <i class="fa fa-map-marker"></i>
              {{ detailBusiness.Address }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {RepositoryFactory} from "@apis/repositoryFactory";
import Map from 'src/pages/Maps.vue'

const UserRepository = RepositoryFactory.get('user');
export default {
  name: "details-partner",
  components: {
    Map
  },
  data () {
    return {
      detailBusiness: []
    }
  },
  async created() {
    try {
      this.$_loading();
      const id = this.$route.query.id;
      this.detailBusiness  = await UserRepository.getOurPartnerById(id);
    } catch (error) {
    } finally {
      this.$_load();
    }
  }
}
</script>

<style scoped lang="scss">
.details-partner {
  padding: 16px;
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 26px;
    .title {
      font-weight: 700;
      font-size: 24px;
      line-height: 34px;
      color: #141822;
      margin-bottom: 0;
    }
  }
  &__content {
    padding: 24px;
    background: #FFFFFF;
    border-radius: 8px;
    .img-arrow {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 25px;
      .name {
        padding-left: 10px;
        font-weight: 700;
        font-size: 20px;
        line-height: 28px;
        color: #43464E;
      }
    }
    .map {
      position: relative;
      /deep/#map {
        min-height: 80vh;
      }
      .address {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 24px;
        background: rgba(255, 255, 255, 0.7);
        backdrop-filter: blur(16px);
        border-radius: 4px;
        width: 85%;
        padding: 20px;
        .title {
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 700;
          font-size: 24px;
          line-height: 28px;
          color: #141822;
          text-transform: capitalize;
        }
        p {
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          color: #141822;
        }
      }
    }
  }
}

</style>
