<template>
  <div>
    <div class="create-qr__title">
      {{ $t("code.title", [walletSelected.label]) }}
    </div>
    <div class="create-qr__content" v-if="step === 1">
      <p class="create-qr__content--title">
        {{ $t("code.sub", [walletSelected.label]) }}
        <span>{{ $options.filters.toCryptocurrency(amount) }} {{ type }}</span>
      </p>
      <div class="d-flex flex-column" :class="{'flex-column-reverse': reverse}">
        <div class="create-qr__content--amount">
          <BaseInputCustom class-name="theme-light" ref="amount" v-model="amount" type="text" name="Amount" required
            :limitInput="20" thousandsSeparatorSymbol="," :label="$t('fields.Amount')" :minValue="0"
            error-required="Please enter Amount" :disabled="reverse" />
          <BaseSelect ref="type" :input-value="type" name="type" :label="$t('qrcode.unit')" :items="amountType"
            :is-show-default="false" required key-loop="index" @change="type = $event" />
        </div>
        <div class="text-center py-2">
          <img @click="reverse = !reverse"
            :src=" !reverse ? '/assets/img/icon/transfer-2.svg' : '/assets/img/icon/transfer-3.svg'" alt="" />
        </div>
        <div class="create-qr__content--amount">
          <BaseInputCustom class-name="theme-light" ref="amountReceive" v-model="amountReceive" type="text"
            name="Amount" required :label="$t('qrcode.receive-amount')" error-required="Please enter Amount"
            :thousandsSeparatorSymbol="','" :disabled="!reverse" />
          <BaseSelect ref="type" :input-value="typeReceive" name="type" label="Wallet Type" :items="amountReceiveType"
            :is-show-default="false" required text-field="label" value-field="value" key-loop="index"
            @change="typeReceive = $event" />
        </div>
      </div>
      <BaseInputCustom class-name="theme-light" ref="reason" v-model="reason" type="textarea" name="Reason" required
        :label="$t('fields.Reason')" :limitInput="50" :placeholder="$t('placeholder.textarea.max')"
        error-required="Please enter Message" />
      <div class="create-qr__action">
        <button class="btn btn-primary btn-round create-qr__btn-cancel" @click="$emit('cancel')">
          {{ $t("action.cancel") }}
        </button>
        <button class="btn btn-primary create-qr__btn-create" @click="next" :class="{ disabled: !(amount && reason) }">
          {{ $t("action.create") }}
        </button>
      </div>
    </div>
    <div class="create-qr__content" v-else-if="amount && reason">
      <p class="create-qr__content--title">
        {{ $t("code.sub2", [walletSelected.label]) }}
        <span>{{ $options.filters.toCryptocurrency(amount) }} {{ type }}</span>
      </p>
      <div class="qr-code">
        <qrcode-vue id="qrimage" :value="qrCodeData" :size="size" level="M"></qrcode-vue>
      </div>
      <div class="icon-save">
        <a @click="downloadQR('qrcode-for-scan.png', qrCodeData)"><img src="/img/wallet/icon-save.svg"
            alt="icon-save" /></a>
      </div>
      <template v-if="isPage">
        <button class="btn btn-outline-primary px-5 mx-auto d-block" @click="$emit('cancel')">
          {{ $t('action.back-to-receive') }}
        </button>
      </template>
      <div class="create-qr__action" v-else>
        <button class="btn btn-primary btn-round create-qr__btn-cancel" @click="step = 1">
          {{ $t("action.back") }}
        </button>
        <button class="btn btn-primary create-qr__btn-create" @click="$emit('cancel')">
          {{ $t("action.close") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import QrcodeVue from "qrcode.vue";
import BaseInputCustom from "@components/Inputs/BaseInputCustom";
import BaseInput from "@components/Inputs/BaseInput";
import { WALLET_TRANSFER } from "@/constants";
import { RepositoryFactory } from "@apis/repositoryFactory";
const WalletRepository = RepositoryFactory.get("wallet");
import BaseSelect from "@/components/Inputs/BaseSelect.vue";
import helpers from "@/core/mixins/helpers";
import {mapGetters} from "vuex";
import { homeUrl } from "@/constants/config";

export default {
  name: "ModalCreateQr",
  components: {
    QrcodeVue,
    BaseInputCustom,
    BaseInput,
    BaseSelect,
  },
  props: {
    isPage: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [helpers],
  data() {
    return {
      reverse: false,
      step: 1,
      amountMark: "",
      amount: "",
      reason: "",
      amountType: ["VND", "USD", "VLG"],
      type: "USD",
      amountReceive: "0",
      amountReceiveType: WALLET_TRANSFER,
      typeReceive: "VLGTokenAvailable",
      rateUSDtoVnd: null,
      rateVlgToUsd: null,
      rateVlgToVnd: null,
    };
  },
  async mounted() {
    this.step = 1;
    const rate = await WalletRepository.getExchangeRate();
    this.rateUSDtoVnd = rate.UsdToVnd;
    this.rateVlgToUsd = rate.VlgToUsd;
    this.rateVlgToVnd = rate.UsdToVnd * rate.VlgToUsd;
  },
  computed: {
    ...mapGetters({
      walletValue: "getWallet",
    }),
    size() {
      let width = window.innerWidth;
      return width > 456 ? 364 : 282;
    },
    walletAddress() {
      return this.walletValue && this.walletValue[this.typeReceive] && this.walletValue[this.typeReceive].WalletAddress
        ? this.walletValue[this.typeReceive].WalletAddress
        : ""
    },
    qrCodeData() {
      const jsonData = {
        address: this.walletAddress,
        amount: this.amount,
        message: this.reason,
        token: this.typeReceive,
        unit: this.type
      }
      const searchParams = new URLSearchParams(jsonData);
      return homeUrl + '/app/wallets/transfers?' + searchParams
    },
    walletSelected() {
      return WALLET_TRANSFER.find((i) => i.value == this.typeReceive);
    },
  },
  watch: {
    // amountMark(val) {
    //   this.amount = Number(val.replace(/,/g, ""));
    // },
    amountReceive(val) {
      if (this.reverse) {
        if (this.type === "VND") {
          this.amount =
            this.typeReceive != "VLGTokenAvailable"
              ? val / this.rateUSDtoVnd
              : val / this.rateVlgToVnd;
        } else if (this.type === "USD") {
          this.amount =
            this.typeReceive != "VLGTokenAvailable"
              ? val
              : val * this.rateVlgToUsd;
        } else if (this.type === "VLG") {
          this.amount =
            this.typeReceive != "VLGTokenAvailable"
              ? val * this.rateVlgToUsd
              : val;
        }
      }
    },
    amount(val) {
      if (!this.reverse) {
        if (this.type === "VND") {
          this.amountReceive =
            this.typeReceive != "VLGTokenAvailable"
              ? val / this.rateUSDtoVnd
              : val / this.rateVlgToVnd;
        } else if (this.type === "USD") {
          this.amountReceive =
            this.typeReceive != "VLGTokenAvailable"
              ? val
              : val / this.rateVlgToUsd;
        } else if (this.type === "VLG") {
          this.amountReceive =
            this.typeReceive != "VLGTokenAvailable"
              ? val * this.rateVlgToUsd
              : val;
        }
        // this.amountReceive = new Intl.NumberFormat('en-US').format(this.amountReceive)
      }
    },
    typeReceive(val) {
      if (this.reverse) {
        if (this.type === "VND") {
          this.amountReceive =
            val != "VLGTokenAvailable"
              ? this.amount / this.rateUSDtoVnd
              : this.amount / this.rateVlgToVnd;
        } else if (this.type === "USD") {
          this.amountReceive =
            val != "VLGTokenAvailable"
              ? this.amount
              : this.amount / this.rateVlgToUsd;
        } else if (this.type === "VLG") {
          this.amountReceive =
            val != "VLGTokenAvailable"
              ? this.amount * this.rateVlgToUsd
              : this.amount;
        }
      } else {
        if (this.type === "VND") {
          this.amount =
            val != "VLGTokenAvailable"
              ? this.amountReceive / this.rateUSDtoVnd
              : this.amountReceive / this.rateVlgToVnd;
        } else if (this.type === "USD") {
          this.amount =
            val != "VLGTokenAvailable"
              ? this.amountReceive
              : this.amountReceive * this.rateVlgToUsd;
        } else if (this.type === "VLG") {
          this.amount =
            val != "VLGTokenAvailable"
              ? this.amountReceive * this.rateVlgToUsd
              : this.amountReceive;
        }
      }
      // this.amountReceive = new Intl.NumberFormat('en-US').format(this.amountReceive)
    },
    type(val) {
      if (this.reverse) {
        if (val === "VND") {
          this.amountReceive =
            this.typeReceive != "VLGTokenAvailable"
              ? this.amount / this.rateUSDtoVnd
              : this.amount / this.rateVlgToVnd;
        } else if (val === "USD") {
          this.amountReceive =
            this.typeReceive != "VLGTokenAvailable"
              ? this.amount
              : this.amount / this.rateVlgToUsd;
        } else if (val === "VLG") {
          this.amountReceive =
            this.typeReceive != "VLGTokenAvailable"
              ? this.amount * this.rateVlgToUsd
              : this.amount;
        }
      } else {
        if (val === "VND") {
          this.amount =
            this.typeReceive != "VLGTokenAvailable"
              ? this.amountReceive / this.rateUSDtoVnd
              : this.amountReceive / this.rateVlgToVnd;
        } else if (val === "USD") {
          this.amount =
            this.typeReceive != "VLGTokenAvailable"
              ? this.amountReceive
              : this.amountReceive * this.rateVlgToUsd;
        } else if (val === "VLG") {
          this.amount =
            this.typeReceive != "VLGTokenAvailable"
              ? this.amountReceive * this.rateVlgToUsd
              : this.amountReceive;
        }
      }
      // this.amountReceive = new Intl.NumberFormat('en-US').format(this.amountReceive)
    },
  },
  methods: {
    changeValue() { },
    async submit() { },
    next() {
      this.step = 2;
    },
  },
};
</script>

<style scoped lang="scss">
.create-qr {
  &__title {
    font-family: "Overpass";
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 44px;
    color: #141822;
    text-align: center;

    @media only screen and (max-width: 515px) {
      font-size: 24px;
      line-height: 32px;
    }
  }

  &__content {
    margin-top: 1rem;

    &--amount {
      display: grid;
      grid-template-columns: 77% 20%;
      justify-content: space-between;
    }

    &--title {
      font-weight: 400;
      font-size: 20px;
      line-height: 28px;
      text-align: center;
      color: #43464e;

      span {
        font-weight: 700;
      }
    }

    .qr-code {
      margin: 0 auto;
      border: 1px solid #adaeb2;
      border-radius: 8px;
      padding: 18px;
      width: 400px;
      margin-bottom: 24px;

      @media only screen and (max-width: 456px) {
        width: 320px;
      }
    }

    .icon-save {
      margin-bottom: 24px;
      display: flex;
      justify-content: center;

      a {
        cursor: pointer;
        background: #f7f7f7;
        padding: 18px;
        border-radius: 100%;

        img {
          width: 28px;
        }
      }
    }
  }

  &__password {
    margin-top: 15px;
  }

  &__btn {
    height: 40px;
  }

  &__action {
    display: grid;
    grid-template-columns: 47% 47%;
    justify-content: space-between;
  }

  &__btn-cancel {
    color: #007bff;
    background: #ffffff;
    border: 1px solid #007bff;

    &:hover {
      background: #007bff;
      color: #ffffff;
    }
  }

  &__btn-create {
    &.disabled {
      pointer-events: none;
    }
  }
}

::v-deep .eye {
  color: #43464e !important;
}

::v-deep .base-select {
  margin-bottom: 20px;

  &__label {
    color: #43464e;
  }
}

::v-deep .base-select__wrap {
  background: #f7f7f7;

  .arrow-down {
    color: #43464e;
  }
}

::v-deep .base-select__inner {
  p {
    color: #898b91;
  }
}

::v-deep input:disabled {
  background: #e0e2e6 !important;
  color: #898b91 !important;
}

::v-deep .base-select__options {
  z-index: 9;
  background: #ffffff;

  .base-select__item {
    position: relative;

    &.active {
      position: relative;

      &::before {
        display: block;
        content: "";
        width: 20px;
        height: 20px;
        background-image: url("/img/wallet/icon-check.svg");
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 16px;
      }
    }

    &:hover {
      background: #f7f7f7;
      color: #101828;
    }

    .base-select__link {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #101828;
      border: none;
      border-top: none !important;

      &:hover {
        text-decoration: none !important;
      }
    }
  }
}

/deep/ .base-select__icon {
  right: 5px;
}

/deep/ textarea {
  background: #f7f7f7;
  border-radius: 8px;
  outline: none !important;
  border: none;
  font-family: "Overpass";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #141822;
  padding: 10px;
}
</style>
