<template>
  <div class="pools">
    <div class="pools__table">
      <b-table
        ref="table"
        responsive
        class="vuetable"
        :current-page="params.PageNumber"
        selectedVariant="primary"
        :fields="bootstrapTable.fields"
        :items="dataProvider"
        show-empty
        :busy.sync="isLoading"
      >
        <template #table-busy>
          <div class="text-center my-2">
            <BaseLoading
              v-if="isLoading"
              class="loading"
              :loading="isLoading"
            />
          </div>
        </template>
        <template #empty>
          <div class="empty-table">
            <img src="/img/empty-folder.png" alt="img empty" />
            <span>No data</span>
          </div>
        </template>
      </b-table>
      <b-pagination
        first-text="First"
        prev-text="Prev"
        next-text="Next"
        last-text="Last"
        align="right"
        :total-rows="params.TotalCount"
        :per-page="params.PageSize"
        v-model="params.PageNumber"
        v-show="params.TotalCount > params.PageSize"
      >
      </b-pagination>
    </div>
  </div>
</template>

<script>
import { RepositoryFactory } from "@/apis/repositoryFactory";
const CommissionRepository = RepositoryFactory.get("commission");
import { STATUS_COLOR } from "@/constants";
import globalMixin from "@/core/mixins/global";

export default {
  data() {
    return {
      STATUS_COLOR,
      showPopup: false,
      updateAccountPopup: false,
      SearchString: null,
      updateItem: {},
      params: {
        PageNumber: 1,
        PageSize: 10,
        FromDate: null,
        ToDate: null,
        TotalCount: 0,
      },
      bootstrapTable: {
        fields: [
          {
            key: "VLGCashAmount",
            label: this.$t('fields.VLGCashAmount'),
            formatter: (value) => `$${new Intl.NumberFormat('en-US').format(value)}`,
          },
          {
            key: "VLGOFSAmount",
            label: this.$t('fields.VLGOFSAmount'),
            formatter: (value) => `${new Intl.NumberFormat('en-US').format(value)}`,
          },
          {
            key: "VTPBonusAmount",
            label: this.$t('fields.VTPBonusAmount'),
            formatter: (value) => `${new Intl.NumberFormat('en-US').format(value)}`
          },
          {
            key: "Username",
            label: this.$t('fields.Username'),
          },
          {
            key: "BonusPercent",
            label: this.$t('fields.BonusPercent'),
            formatter: (value) => `${value}%`,
          },
          {
            key: "DateTime",
            label: this.$t('fields.DateTime'),
            formatter: (value) => this.$options.filters.formatDateTime(value),
          },
        ],
      },
    };
  },
  components: {
    BaseLoading: () => import("@components/Inputs/BaseLoading"),
  },
  mixins: [globalMixin],
  methods: {
    async dataProvider() {
      const params = {
        SearchString: this.SearchString,
        PageNumber: this.params.PageNumber,
        PageSize: this.params.PageSize,
      };
      this.$_loading();
      const data = await CommissionRepository.indirect(params);
      this.$_load();
      this.params.PageNumber = data.CurrentPage;
      this.params.TotalCount = data.TotalCount;
      this.params.PageSize = data.PageSize;
      return data.Result;
    },
  },
};
</script>
