<template>
  <div>
    <div class="mt-5">
      <div class="container">
        <div class="row">
          <b-card
            class="px-4 col-12 col-md-6 bg_footer ml-auto mr-auto"
            body-class="row px-2"
            v-if="!showDetail"
          >
            <div class="col-12">
              <p class="text-center" style="font-weight: bold;font-size: 40px;">CHECKIN FORM</p>
              <div v-html="$t('event.checkin.intro')">
              </div>
            </div>
            <b-form class="col-12" @submit.prevent="submitForm">
              <b-form-group :label="$t('users.infor.phonenumber')">
                <v-select
                  :reduce="(member) => member.PhoneNumber"
                  label="FullName"
                  @search="onSearch"
                  :options="members"
                  type="tel"
                  pattern="[0-9.]+"
                  v-model="$v.formData.PhoneNumber.$model"
                  onkeyup="this.querySelector('input').value = this.querySelector('input').value.replace(/[^\d]/,'')"
                  maxlength="11"
                  :placeholder="$t('event.checkin.phone-length')"
                >
                  <span slot="no-options">{{$t('event.checkin.phone-note-match')}}</span>
                </v-select>
                <div
                  :class="{
                    'invalid-feedback position-relative mt-0': true,
                    'd-block':
                      $v.formData.PhoneNumber.$error &&
                      !$v.formData.PhoneNumber.required,
                  }"
                >
                  {{$t('event.checkin.phone-required')}}
                </div>
              </b-form-group>
              <div class="mt-4 text-center">
              <button
                class="px-5"
                type="submit"
                :class="{ 'button--loading': isLoading }"
                @click="submitForm()"
              >
                <span>{{$t('forms.submit')}}</span>
              </button>
              </div>
            </b-form>
             <div class="col-12">
              <div class="checkin-note" v-html="$t('event.checkin.note')">
              </div>
            </div>
          </b-card>
          <b-card
            class="px-4 col-12 col-md-6 bg_footer ml-auto mr-auto"
            body-class="row px-2"
            v-else
          >
            <div class="col-12" v-html="$t('event.checkin.result')">
            </div>
          </b-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import debounce from "lodash.debounce";
import { validationMixin } from "vuelidate";
const { required } = require("vuelidate/lib/validators");
import { RepositoryFactory } from "@/apis/repositoryFactory";
import { NOTIFICATION_ICON } from "@/constants";
const EventRepository = RepositoryFactory.get("event");

export default {
  metaInfo: {
    title: "Policy",
  },
  data() {
    return {
      fullname: "",
      codes: [],
      result: "",
      formData: {
        Code: "",
        PhoneNumber: "",
        Email: "",
      },
      isLoading: false,
      status: "NEW",
      members: [],
      showDetail: false,
    };
  },
  components: {
    "v-select": vSelect,
  },
  mixins: [validationMixin],
  validations: {
    formData: {
      Code: { },
      PhoneNumber: { },
      Email: { },
    },
  },
  methods: {
    onSearch(search, loading) {
      const regex = '[0-9.]+';
      if (search.length >= 3 && search.match(regex)) {
        loading(true);
        this.search(loading, search, this);
      }
    },
    search: debounce(async (loading, search, vm) => {
      try {
        const data = await EventRepository.searchPhonenumber(search);
        vm.members = data;
      } catch (error) {
        vm.members = [];
      } finally {
        loading(false);
      }
    }, 350),
    async submitForm() {
      var phoneNumber = this.$v.formData.PhoneNumber;
      this.$v.formData.$touch();
      if (!this.$v.formData.$anyError || phoneNumber != null) {
        try {
          this.isLoading = true;
          const res = await EventRepository.checkinTicket(this.formData);
          // this.fullname = res.Fullname;
          // this.codes = res.Codes;
          this.showDetail = true

          this.formData.Code = "";
          this.formData.PhoneNumber = "";
          this.formData.Email = "";
        } catch (error) {
          this.showDetail = true
          // this.$notifyPopup(NOTIFICATION_ICON.ERROR,
          //   this.$t('swal.title.fail'),
          //   error.response.data.message,
          //   {
          //     action1Text: 'OK',
          //     action1Func: 'OK'
          //   }
          // )
        } finally {
          this.$v.formData.$reset();
          this.isLoading = false;
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/.iconsminds-left {
  display: none;
}
button[type=submit] {
  height: 40px;
  background: transparent;
  border: 1px solid #007BFF;
  border-radius: 8px;
  // -webkit-box-pack: center;
  justify-content: center;
  // -webkit-box-align: center;
  align-items: center;

  span {
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    color: #007bff;
    padding-top: 3px;
  }
}
.btn-disabled {
  pointer-events: none;
  opacity: 0.6;
}
</style>
<style>
.footer {
  display: none !important;
}
.vs__search::placeholder {
  opacity: 0.5;
}
.event-info {
  margin-left: 50px;
}
.checkin-note {
  color: red;
  margin-top: 20px;
}
@media(max-width:767px)  {
  .event-info {
    margin-left: 20px;
  }
}
</style>
