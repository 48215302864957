<template>
  <BaseModal :config="config">
    <template slot="content">
      <ModalContent @cancel="cancel" @refresh="$emit('refresh')" />
    </template>
  </BaseModal>
</template>

<script>
import ModalContent from "./ModalContent/convert-vlg-to-vmm";
import { MODAL } from "@/constants";

export default {
  name: "ModalConvertToCash",
  components: {
    ModalContent,
  },
  data() {
    return {
      config: {
        id: MODAL.CONVERT_TO_VMM,
        class: "modal--convert-to-cash",
        noCloseOnBackDrop: false,
        centered: true,
        theme: "light",
      },
    };
  },
  methods: {
    cancel() {
      this.$bvModal.hide(this.config.id);
    },
  },
};
</script>

<style scoped lang="scss">
</style>
