<template>
  <div>
    <div> Call function</div>
    <pre>
      this.$notifyPopup(NOTIFICATION_ICON.ERROR, 'error', 'desc', {action1Text: 'confirm', action2Text: 'deny', action1Func: this.confirm,  action2Func: this.deny })
    </pre>
    <div>
      Action
    </div>
    <pre>
      methods: {
        confirm() {
          console.log('confirmed.')
        },
        deny() {
          console.log('denied.')
        }
      }
    </pre>
  </div>

</template>

<script>
const {NOTIFICATION_ICON} = require("@/constants");
export default {
  name: "NotificationPopup",
  mounted() {
    this.$notifyPopup(NOTIFICATION_ICON.ERROR, 'error', 'desc', {action1Text: 'confirm', action2Text: 'deny', action1Func: this.confirm,  action2Func: this.deny })
  },
  methods: {
    confirm() {
      console.log('confirmed.')
    },
    deny() {
      console.log('denied.')
    }
  },
}
</script>

<style scoped>
  pre {
    width: 800px;
    background: #969EBB;
  }
</style>
