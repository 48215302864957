<template>
  <div>
    <DetailPartner />
  </div>
</template>

<script>
import DetailPartner from '@containers/Partners/details-partner.vue'
export default {
  components: {
    DetailPartner
  }
}
</script>

<style>

</style>
