<template>
  <div>
    <template v-if="step == 1">
      <div class="bg-white p-4">
        <div class="receive__title">{{ $t('bottom-bar.Receive') }}</div>
        <div class="receive__content mb-5">
          <BaseSelect
            class="theme-light"
            ref="token"
            :input-value="token"
            name="token"
            :label="$t('transfer.receive')"
            :items="tokens"
            text-field="label"
            value-field="value"
            :is-show-default="false"
            key-loop="index"
            @change="token = $event"
          />
          <div id="linkQr">
            <qrcode-vue
              class="qr-img"
              id="qrimage"
              :value="walletQrCode"
              size="338"
              level="M"
            ></qrcode-vue>
          </div>
          <div class="address">
            <p class="mt-4">{{ $t("wallet.address") }}</p>
            <a class="d-block" @click="copyLink(walletAddress)">
              <BaseInput
                disabled
                :value="walletAddress"
                addonRightIcon="icon-icon-copy"
                class="link-ref"
              />
            </a>
          </div>
          <div class="d-flex justify-content-center">
            <div class="icon-save mr-2">
              <a @click="share"
                ><img src="/img/wallet/icon-share.svg" alt="icon-save"
              /></a>
            </div>
            <div class="icon-save">
              <a @click="downloadQR(`${token}-QRCode.png`, walletQrCode)"
                ><img src="/img/wallet/icon-save.svg" alt="icon-save"
              /></a>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="currentUser.AllowCreateQr"
        class="py-2 bg-white mt-3 fixed-bottom"
      >
        <button
          class="btn btn-outline-primary w-75 mx-auto d-block"
          @click="step = 2"
        >
          {{ $t('wallet.create-qr-transfer') }}
        </button>
      </div>
    </template>
    <div v-if="step == 2" class="bg-white p-4">
      <create-qr @clean="clean" :isPage="true" @cancel="step = 1" />
    </div>
  </div>
</template>

<script>
import { MODAL } from "@/constants";
import QRCode from 'qrcode'
import QrcodeVue from "qrcode.vue";
import { homeUrl } from "@/constants/config";
import BaseSelect from "@/components/Inputs/BaseSelect.vue";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import helpers from "@/core/mixins/helpers";
import CreateQr from "@/containers/Wallet/ModalContent/create-qr.vue";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "ModalReceive",
  components: {
    QrcodeVue,
    BaseInput,
    BaseSelect,
    CreateQr,
  },
  mixins: [helpers],
  data() {
    return {
      config: {
        id: MODAL.RECEIVE,
        class: "modal--receive",
        noCloseOnBackDrop: false,
        centered: true,
        theme: "light",
      },
      step: 1,
      tokens: [
        { label: "VLG", value: "VLGTokenAvailable" },
        { label: "VUSD", value: "VLGCash" },
        { label: "VTP", value: "VLGPlus" },
      ],
      token: "VLGTokenAvailable",
    };
  },
  methods: {
    ...mapActions(["fetchWallet"]),
    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    },
    async share() {
      const opts = {
        errorCorrectionLevel: 'M',
        width: 600,
      };
      let file
      const that = this
      QRCode.toDataURL(this.walletQrCode, opts, async function (err, url) {
        file = that.dataURLtoFile(url, 'qr-code.png');
      })
      const files = [file]
      const token = this.tokens.find(i => i.value = this.token)
      if(navigator.canShare && navigator.canShare({ files })) {
        navigator
          .share({
            title: "Transfer Token",
            text: `Transfer ${token.label} to wallet address ${this.walletAddress}`,
            files
          })
          .then(() => console.log("Successful share"))
          .catch((error) => console.log("Error sharing", error));
      }
    },
  },
  computed: {
    ...mapGetters('Auth', {currentUser: 'currentUser'}),
    ...mapGetters({
      walletValue: "getWallet",
    }),
    walletAddress() {
      return this.walletValue && this.walletValue[this.token] && this.walletValue[this.token].WalletAddress
        ? this.walletValue[this.token].WalletAddress
        : ""
    },
    walletQrCode() {
      if (this.walletValue && this.walletValue[this.token] && this.walletValue[this.token].WalletAddress) {
        const data =  {
          token: this.token,
          address: this.walletValue[this.token].WalletAddress
        }
        // return JSON.stringify(data)
        const searchParams = new URLSearchParams(data);
        return homeUrl + '/app/wallets/transfers?' + searchParams
      }
      return ""
    }
  },
  async created() {
    try {
      if (this.$store.state.Auth.currentUser && Object.keys(this.$store.state.Auth.currentUser).length !== 0) {
        await this.fetchWallet()
      }
    } catch (error) {
      console.error(error)
    }
  }
};
</script>

<style scoped lang="scss">
.receive {
  width: 407px;
  color: #ffffff;
  margin-top: 32px;
  &__title {
    font-weight: 700;
    font-size: 32px;
    line-height: 44px;
    color: #141822;
    text-align: center;
  }
  &__content {
    margin-top: 24px;
    .qr-img {
      width: 100%;
      // border: 1px solid #adaeb2;
      border-radius: 8px;
      text-align: center;
      /deep/ canvas {
        width: 100%;
        padding: 1rem;
        border-radius: 8px;
        border: 1px solid #adaeb2;
      }
    }
    .icon-save {
      margin-top: 24px;
      display: flex;
      justify-content: center;
      a {
        background: #f7f7f7;
        padding: 18px;
        border-radius: 100%;
        img {
          width: 28px;
        }
      }
    }
    .address {
      a {
        text-decoration: none;
      }
      p {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #43464E;
        margin-bottom: 8px;
      }
      /deep/ {
        .form-control:disabled {
          background: #F7F7F7;
          border: 1px solid #E8E8E9;
          border-radius: 8px 0 0 8px;
          font-size: 16px;
          line-height: 24px;
          color: #43464E;
          border-right: none;
        }
        .input-group-text {
          background: #F7F7F7;
          border: 1px solid #E8E8E9;
          border-left: none;
          border-radius: 0 8px 8px 0;
        }

      }
    }
  }
}
::v-deep .base-select {
  margin-bottom: 20px;
  &__label {
    color: #43464e;
  }
}
::v-deep .base-select__wrap {
  background: #f7f7f7;
  .arrow-down {
    color: #43464e;
  }
}
::v-deep .base-select__inner {
  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #141822;
  }
}
/deep/ .base-select__options {
  background: #ffffff;
  .base-select__item {
    position: relative;
    &.active {
      position: relative;
      &::before {
        display: block;
        content: "";
        width: 20px;
        height: 20px;
        background-image: url("/img/wallet/icon-check.svg");
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 8px;
      }
    }
    &:hover {
      background: #f7f7f7;
      color: #101828;
      text-decoration: none;
    }
    .base-select__link {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #101828;
      border: none;
    }
  }
}
.fixed-bottom {
  position: fixed;
  bottom: 60px;
  width: 100%;
  z-index: 1;
}
</style>
