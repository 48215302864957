<template>
  <div class="kyc-detail">
    <div class="img-arrow" @click="$router.push('/profile/kyc')">
      <img src="/img/arrow-back.svg" alt="arrow-back" class="arrow-back">
      <span>{{$t("kyc.back-to-list")}}</span>
    </div>
    <div class="mt-3 kyc-detail__title d-flex align-items-center">
      <span>{{$t('kyc.details')}} </span>
      <span :class="['status', info.Status]">{{info.Status}}</span></div>
    <div class="row">
      <div class="mt-3 kyc-detail__grid-content col-lg-6 col-md-12">
        <div class="title">{{$t("kyc.forms.Personal")}}</div>
        <div class="kyc-detail__table mt-3">
          <table>
            <tr>
              <td class="th">{{$t("users.infor.first-name")}}</td>
              <td class="td" style="white-space: break-spaces">{{info.FirstName || ''}}</td>
            </tr>
            <tr>
              <td class="th">{{$t("users.infor.last-name")}}</td>
              <td class="td" style="white-space: break-spaces">{{info.LastName || ''}}</td>
            </tr>
            <tr>
              <td class="th">{{$t("users.infor.email")}}</td>
              <td class="td">{{info.Email || ''}}</td>
            </tr>
            <tr>
              <td class="th">{{$t("users.infor.DateOfBirth")}}</td>
              <td class="td">{{(info.DateOfBirth || '') | formatDateNoOffset}}</td>
            </tr>
            <tr>
              <td class="th">{{$t("users.infor.DocumentNumber")}}</td>
              <td class="td">{{info.DocumentNumber || ''}}</td>
            </tr>
            <tr>
              <td class="th">{{$t("users.infor.DocumentIssueDate")}}</td>
              <td class="td">{{(info.DocumentIssueDate || '') | formatDateNoOffset}}</td>
            </tr>
            <tr>
              <td class="th">{{$t("users.infor.CityOfBirth")}}</td>
              <td class="td">{{info.CityOfBirth || ''}}</td>
            </tr>
            <tr>
              <td class="th">{{$t("users.infor.CountryOfBirth")}}</td>
              <td class="td">{{info.CountryOfBirth || ''}}</td>
            </tr>
            <tr>
              <td class="th">{{$t("users.infor.AddressDocumentType")}}</td>
              <td class="td" style="white-space: break-spaces;">{{info.AddressDocumentType || ''}}</td>
            </tr>
            <tr>
              <td class="th">{{$t("users.infor.FullAddress")}}</td>
              <td class="td">{{info.FullAddress || ''}}</td>
            </tr>
            <tr>
              <td class="th">{{$t("users.infor.PostalCode")}}</td>
              <td class="td">{{info.PostalCode || ''}}</td>
            </tr>
            <tr>
              <td class="th">{{$t("users.infor.CountryOfLiving")}}</td>
              <td class="td">{{info.CountryOfLiving}}</td>
            </tr>
            <tr>
              <td class="th">{{$t("users.infor.city")}}</td>
              <td class="td">{{info.City || ''}}</td>
            </tr>
            <tr>
              <td class="th">{{$t("reject-reason")}}</td>
              <td class="td">{{info.RejectReason || ''}}</td>
            </tr>

          </table>
        </div>
      </div>
      <div class="mt-3 kyc-detail__grid-content col-lg-6 col-md-12">
        <div class="title">{{$t("kyc.forms.document")}}</div>
        <div class="kyc-detail__table mt-3">
          <table>
            <tr>
              <td class="th">{{$t("kyc.forms.CardFront")}}</td>
              <td class="td td-image"><img v-if="idFrontPath" :src="idFrontPath" alt="frontId" class="w-100 h-100"></td>
            </tr>
            <tr>
              <td class="th">{{$t("kyc.forms.CardBack")}}</td>
              <td class="td td-image"><img v-if="idBackPath" :src="idBackPath" alt="backId" class="w-100 h-100"></td>
            </tr>
            <tr>
              <td class="th">{{$t("kyc.forms.GasBill")}}</td>
              <td class="td td-image">
                <img :src="gasBillPath" class="w-100 h-100" v-if="gasBillPath">
              </td>
            </tr>
          </table>
        </div>
        <template v-if="isBusinessAcc">
          <div class="title mt-3">{{$t("kyc.forms.business.index")}}</div>
          <div class="kyc-detail__table mt-3">
            <table>
              <tr>
                <td class="th">{{$t("kyc.forms.business.address")}}</td>
                <td class="td td-image">
                  {{ info.BusinessAddress }}
                </td>
              </tr>
              <tr>
                <td class="th">{{$t("kyc.forms.business.certificate")}}</td>
                <td class="td td-image">
                  <img v-if="businessCertificate" :src="businessCertificate" alt="BusinessCertificate" class="w-100 h-100">
                </td>
              </tr>
              <tr>
                <td class="th">{{$t("kyc.forms.business.bank-statement")}}</td>
                <td class="td td-image">
                  <img v-if="businessBankStatement" :src="businessBankStatement" class="w-100 h-100" alt="BusinessBankStatement">
                </td>
              </tr>
            </table>
          </div>
        </template>
      </div>
    </div>

  </div>
</template>

<script>
import {apiImageUrl} from "@/constants/config";
import {RepositoryFactory} from "@apis/repositoryFactory"
const KYCRepository = RepositoryFactory.get('kyc')

export default {
  mounted () {
    this.getKYCInfo()
  },
  computed: {
    id() {
      return this.$route.params.id
    },
    isBusinessAcc() {
      return this.$store.state.Auth.currentUser && this.$store.state.Auth.currentUser.Type === "BUSINESS"
    },
    idFrontPath() {
      return this.info && this.info.IdentityCardFront ? apiImageUrl + this.info.IdentityCardFront : ''
    },
    idBackPath() {
      return this.info && this.info.IdentityCardBack ? apiImageUrl + this.info.IdentityCardBack : ''
    },
    gasBillPath() {
      return this.info && this.info.UtilityBill ? apiImageUrl + this.info.UtilityBill : ''
    },
    businessCertificate() {
      return this.info && this.info.BusinessCertificate ? apiImageUrl + this.info.BusinessCertificate[0] : ''
    },
    businessBankStatement() {
      return this.info && this.info.BusinessBankStatement ? apiImageUrl + this.info.BusinessBankStatement[0] : ''
    }
  },
  methods: {
    async getKYCInfo () {
      this.isLoading = true
      try {
        this.info = await KYCRepository.getKYCSubmittedDetails(this.id)
      } catch (e) {
        await this.$router.push("/profile/kyc")
        this.$alertMsg('fail', e.response.data.message)
      }
      this.isLoading = false
    }
  },
  data() {
    return {
      info: [],
      isLoading: false
    }
  }
}
</script>

<style scoped lang="scss">
  .kyc-detail {

    color: #43464E;
    &__title {
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 28px;
      padding-bottom: 16px;
      border-bottom: 1px solid #EAEAEA;
    }
    &__grid-content {
      .title {
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
      }
    }
    &__table {
      overflow-y: hidden;
      overflow-x: auto;
      //max-height: 600px;
      table {
        width: 100%;
        border-collapse: separate;
        border-spacing: 0;

        tr:first-child td:first-child { border-top-left-radius: 10px; }
        tr:first-child td:last-child { border-top-right-radius: 10px; }

        tr:last-child td:first-child { border-bottom-left-radius: 10px; }
        tr:last-child td:last-child { border-bottom-right-radius: 10px; }

        tr:first-child td { border-top-style: solid; }
        tr td:first-child { border-left-style: solid; }
        td {
          padding: 16px 24px;
          border: 0.5px solid #EAEAEA;;
          vertical-align: middle;
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          color: #141822;
          white-space: break-spaces;
          border: solid 0.5px #000;
          border-style: none solid solid none;
          img {
            max-width: 192px;
          }
          &.th {
            font-weight: 400;
            color: #898B91;
          }
          &.td {
            font-weight: 600;
            color: #141822;
            width: 70%;
            &.td-image {
              min-width: 175px;
            }
          }
        }
      }
    }
    .status {
      text-transform: capitalize;
      background: #B91E1E;
      border-radius: 11px;
      color: #FFFFFF;
      padding: 5px 25px;
      font-size: 10px;
      line-height: 10px;
      margin-left: 10px;
      &.REJECTED {
        background: #B91E1E;
      }
      &.REVIEW {
        background: #E79330;
      }
      &.APPROVED {
        background: #518555;
      }
      &.REOPEN {
        background: #0064D0;
      }
    }
  }
</style>
