<template>
  <div class="background"/>
</template>
<script>
import {NOTIFICATION_ICON} from "@/constants";
import { RepositoryFactory } from "@apis/repositoryFactory";
const AuthRepository = RepositoryFactory.get('auth')
import { mapActions } from 'vuex'

export default {
  async mounted() {
    const { token, userLogId } = this.$route.query
    if (!token && userLogId) {
      this.$router.push("/")
      return
    }
    await this.confirmRegister(token, userLogId)
  },
  methods: {
    ...mapActions("Auth", {
      signOut: "signOut",
    }),
    handleSuccess() {
      this.signOut();
      this.$router.push("/")
    },
    async confirmRegister(token, userLogId) {
      try {
        await AuthRepository.confirmChangeInfo(token, userLogId)
        await this.$notifyPopup(
          NOTIFICATION_ICON.SUCCESS,
          this.$t("swal.title.success"),
          this.$t("change-info.successfully"),
          {
            action1Text: "OK", 
            action1Func: () => this.handleSuccess(), 
            didCloseFunc: () => this.handleSuccess()
          }
        )
      } catch (e) {
        console.log(e)
        this.$notifyPopup(
          NOTIFICATION_ICON.ERROR,
          this.$t("swal.title.ops"),
          e.response.data.message,
          {action1Text: "OK", action1Func: () => this.$router.push("/"), didCloseFunc: () => this.$router.push("/")}
        )
        this.$store.commit('setCurrentUser', null)
      }
    }
  }
}
</script>

<style scoped>
.background {
  width: 100vw;
  height: 100vh;
  background: #000000;
}
</style>
