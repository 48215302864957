<template>
  <div class="body">
    <policy />
  </div>
</template>

<script>
export default {
  components: {
    policy: () => import("@containers/Policy/index.vue"),
  },
};
</script>

<style scoped lang="scss">
.body {
  background: #141822;
}
</style>
