<template>
  <div>
    <div class="container-fluid customer-header-1 mb-3 text-white" v-if="bonuses.length > 0">
      <slick :options="slickOptions">
        <div class="text-gradient-faststart mb-1 mt-3">
          <p>{{ $t("reached-title") }}</p>
        </div>
        <div class="text-gradient-faststart mb-1 mt-3" v-for="bonus in bonuses" :key="bonus">
          <p>{{ bonus }} &nbsp;&nbsp;&nbsp;</p>
        </div>
      </slick>
    </div>
    <div class="container announcement p-3 p-md-5 font-size-normal">
      <h2 class="text-center mb-4">{{ $t("news.Announcement") }}</h2>
      <b-row>
        <template v-if="newsIsUsing.length > 0">
          <b-colxx xl="4" class="text-center" v-for="image in newsIsUsing" v-bind:key="image.Id">
            <div>
              <p>
                <img v-b-modal.modal1 :src="apiImageUrl + image.ImagePath" @click="activePost = image"
                  class="mw-100 border-radius-2 box-shadow-3" />
              </p>
            </div>
          </b-colxx>
        </template>
        <div v-else class="h-30vh pt-5 col-12">
          <p class="text-white text-center h5">{{ $t("no-notify") }}</p>
        </div>
      </b-row>
    </div>
    <div class="container announcement p-3 p-md-5 font-size-normal">
      <h2 class="text-center mb-4">{{ $t("news.Event") }}</h2>
      <b-row>
        <template v-if="eventsIsUsing.length > 0">
          <b-colxx xl="4" class="text-center" v-for="image in eventsIsUsing" v-bind:key="image.Id">
            <div>
              <p>
                <img v-b-modal.modal1 :src="apiImageUrl + image.ImagePath" @click="activePost = image"
                  class="mw-100 border-radius-2 box-shadow-3" />
              </p>
            </div>
          </b-colxx>
        </template>
        <div v-else class="h-30vh pt-5 col-12">
          <p class="text-white text-center h5">{{ $t("no-event") }}</p>
        </div>
      </b-row>
    </div>
    <b-modal id="modal1" hide-footer hide-header static body-class="p-0" 
    :dialog-class="{'announcement-popup': true, 'mw-100 w-75': images && !images.Detail}"

      content-class=" fixed-close-button ">
      <template v-if="activePost">
        <div class="position-relative">
          <span @click="$bvModal.hide('modal1')" class="position-absolute close-btn"><img
              src="/img/icons/icon-close-white.svg" alt="close-icon" /></span>
        </div>
        <img :src="$apiImageUrl + activePost.ImagePath" class="box-shadow-3 w-100 d-block border-radius-2" />
        <template v-if="activePost.Detail">
          <div class="p-4" v-html="activePost.Detail" />
        </template>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { RepositoryFactory } from "@/apis/repositoryFactory";
const CommisstionRepository = RepositoryFactory.get("commission");
const AssetRepository = RepositoryFactory.get("asset");
import { IMAGE_TYPE } from "@/constants";
import { apiImageUrl } from "@/constants/config";
import globalMixin from "@/core/mixins/global";
import Slick from "vue-slick";
import "slick-carousel/slick/slick.css";

export default {
  metaInfo: {
    title: "Notifications",
  },
  components: { Slick },
  mixins: [globalMixin],
  data() {
    return {
      items: [],
      bonuses: [],
      news: [],
      events: [],
      apiImageUrl,
      listItem: "",
      isShowBanner: false,
      isShowEvent: false,
      activePost: null,
      slickOptions: {
        speed: 2000,
        pauseOnHover: true,
        autoplay: true,
        autoplaySpeed: 15,
        cssEase: "linear",
        variableWidth: true,
        dots: false,
        arrows: false,
        infinite: true,
        useTransform: false,
      },
    };
  },
  async created() {
    this.fetchItem();
    if (this.isLogged) {
      this.fetchFaststart();
    }
  },
  async mounted() {
    const { id } = this.$route.query;
    if (id) {
      try {
        await this.$nextTick()
        this.activePost = await AssetRepository.getPost(id);
        this.$bvModal.show('modal1')

      } catch (error) {
        this.activePost = null
      }
    }
  },
  computed: {
    newsIsUsing() {
      const lang = this.$i18n.locale.toUpperCase();
      return this.news.filter((i) => i.Language.toUpperCase() == lang);
    },
    eventsIsUsing() {
      const lang = this.$i18n.locale.toUpperCase();
      return this.events.filter((i) => i.Language.toUpperCase() == lang);
    },
  },
  methods: {
    async fetchItem() {
      try {
        this.events = await AssetRepository.getBanners(IMAGE_TYPE.EVENT);
        this.news = await AssetRepository.getBanners(IMAGE_TYPE.NEWS);
      } catch (error) {
        console.log(error);
      }
    },
    async fetchFaststart() {
      try {
        const data = await CommisstionRepository.faststarthisweek();
        this.bonuses = data;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
<style lang="scss">
.h-30vh {
  height: 30vh;
}

h2 {
  color: #ffffff;
}

.announcement-popup {
  max-width: 800px;

  .modal-content {
    background: #30353F;
    border-radius: 8px;

    p {
      color: #ffffff;
    }
  }
}

@media (max-width: 769px) {
  .announcement-popup {
    max-width: 100%;

    .fixed-close-button {
      .modal-header {
        position: fixed;
        top: 10px;
        z-index: 999;
        right: 0px;
        border: none;
        background: #fff0;
        padding: 10px;
        border-radius: 0px;
      }
    }
  }
}

.close-btn {
  background: #333333c7;
  padding: 10px;
  border-radius: 50%;
  right: 0;
  cursor: pointer;
}
</style>
