<template>
  <div class="body">
    <faq />
  </div>
</template>

<script>
export default {
  components: {
    faq: () => import("@containers/Faq/index.vue"),
  },
};
</script>

<style scoped lang="scss">
.body {
  background: #141822;
}
</style>
