<template>
  <div>
    <div class="customer-header">
      <p class="text-white">{{ $t("menu-items.shop") }}</p>
    </div>
    <component :is="component" />
  </div>
</template>

<script>
export default {
  metaInfo: {
    title: "THỂ LỆ CHƯƠNG TRÌNH PRE-ORDER VÀ QUAY SỐ ĐẶC BIỆT",
  },
  components: {
    EventProposalVi: () =>
      import("@/containers/Shop/pre-order/event-proposal.vi.vue"),
    EventProposalEn: () =>
      import("@/containers/Shop/pre-order/event-proposal.en.vue"),
  },
  computed: {
    component() {
      return this.$i18n.locale.toUpperCase() == "VI"
        ? "EventProposalVi"
        : "EventProposalEn";
    },
  },
};
</script>
<style scoped>
.bg-custome {
  background-color: #f5f7fa;
}
.customer-header {
  font-family: "Overpass";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 34px;
  color: #141822;
  margin: 24px;
}
</style>