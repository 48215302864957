<template>
  <Network />
</template>

<script>
import Network from '@containers/Network/index.vue'
export default {
  components: {
    Network
  }
}
</script>

<style>

</style>
