<template>
  <div class="faq">
    <div class="container">
      <h2 class="faq__title text-uppercase">{{ this.$t('menu-items.user-guide')}}</h2>
      <div class="faq__content">
        <b-card no-body class="item" v-for="i in 12" :key="i">
          <a
            class="item__heading"
            v-b-toggle="`note-collapse-${i}`"
            role="tab"
            variant="link"
          >
            <span class=" text-uppercase">{{ $t(`userguide.question-${i}`) }}</span>
            <img
              src="/img/icon-arrow-down.svg"
              alt="arrow-down"
              class="rotage-down"
            />
          </a>
          <b-collapse
            :id="`note-collapse-${i}`"
            visible
            accordion="my-accordion"
            role="tabpanel"
          >
            <div
              class="item__content mt-3"
              v-html="$t(`userguide.answer-${i}`)"
            />
          </b-collapse>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style scoped lang="scss">
.faq {
  min-height: 100vh;
  position: relative;
  .container {
    padding: 64px 0;
    text-align: center;
  }
  &__title {
    color: #ffffff;
    font-family: "Overpass";
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 44px;
    margin: 0;
    margin-bottom: 40px;
  }
  &__content {
    .item {
      background: #20242d;
      border: 1px solid #43464e;
      border-radius: 8px;
      padding: 12px 16px;
      font-size: 16px;
      line-height: 24px;
      text-align: left;
      margin-bottom: 24px;
      &__heading {
        &:hover,
        &:focus {
          text-decoration: unset;
          cursor: pointer;
        }
        display: flex;
        justify-content: space-between;
        span {
          color: #ffffff;
          font-weight: 700;
        }
      }
      &__content {
        color: #adaeb2;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        p {
          margin-top: 16px;
        }
      }
      .rotage-down {
        transform: rotate(180deg);
      }
    }
    /deep/ table {
      tdead {
        th {
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          color: #ffffff;
          background: #43464e;
          border-radius: 8px 8px 0px 0px;
        }
      }
      tbody {
        td {
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          color: #adaeb2;
          border: 1px solid #43464e;
        }
      }
    }
  }
  .collapsed > .rotage-down {
    transform: rotate(360deg);
  }
  .not-collapsed > .rotage-down {
    transform: rotate(180deg);
  }
  @media only screen and (max-width: 992px) {
    padding: 12px;
  }
}
</style>
