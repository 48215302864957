<template>
  <div class="reset-pw__background">
    <div class="reset-pw__logo">
      <img src="/img/logo.webp">
    </div>
    <BaseModal :config="config" @hidden="onHideModal">
      <template slot="content">
        <div class="reset-pw__title">
          {{ $t('menu.reset-password')}}
        </div>
        <form @submit.prevent="submit">
          <div class="reset-pw__content">
            <BaseInputCustom
              class-name="reset-pw__password"
              ref="password"
              v-model="formData.Password"
              type="password"
              name="password"
              required
              :label="$t('update-password.new')"
              :max-length="50"
              :min-length="6"
              :limit-input="50"
            />
            <BaseInputCustom
              class-name="reset-pw__password"
              ref="passwordAgain"
              v-model="formData.PasswordAgain"
              type="password"
              name="confirm-pw"
              :same-as="formData.Password"
              :error-same-as="$t('validate.isSame', {0 : $t('password.index')})"
              required
              :max-length="50"
              :min-length="6"
              :limit-input="50"
              :label="$t('update-password.confirmation')"
            />
          </div>
          <button class="btn btn-block btn-primary reset-pw__btn" type="submit" :class="{'button--loading': loading}">
            <span>{{$t('user.reset-password-button')}}</span>
          </button>
        </form>

      </template>
    </BaseModal>
  </div>
</template>

<script>
import {MODAL} from "@/constants";
import BaseInputCustom from "@/components/Inputs/BaseInputCustom.vue"
import validateMixin from '@/core/mixins/validate'
import {reCaptchaKey} from "@/constants/config";
import { RepositoryFactory } from '@/apis/repositoryFactory'
const AuthRepository = RepositoryFactory.get('auth')
export default {
  name: "ModalLogin",
  components: {
    BaseInputCustom
  },
  mixins: [validateMixin],
  data() {
    return {
      config: {
        id: MODAL.RESET_PASSWORD,
        class: 'modal--reset-pw',
        noCloseOnBackDrop: true,
        centered: true,
        notShowCloseButton: true
      },
      formData: {
        PasswordAgain: '',
        Password: '',
        ReCaptchaToken: reCaptchaKey
      },
      loading: false
    }
  },
  mounted() {
    this.$nextTick( () => {
      this.$bvModal.show(MODAL.RESET_PASSWORD)
    })
  },
  computed: {
    token() {
      return this.$route.query.token
    }
  },
  methods: {
    onHideModal() {
      this.resetForm()
      this.$router.push("/")
    },
    resetForm() {
      const refs = ['passwordAgain', 'password']
      this.formData = {
        PasswordAgain: '',
        Password: ''
      }
      this.$_validateMixin_reset(refs)
    },
    async submit () {
      const refs = ['password', 'passwordAgain']
      const refsValid = this.$_validateMixin_refs(refs)
      if (!refsValid) {
        return
      }
      this.loading = true
      try {
        await AuthRepository.resetPassword(this.token, this.formData)
        await this.$router.push("/")
        this.$alertMsg('success', this.$t("swal.title.reset-password"))
      } catch (e) {
        console.log("error", e)
        this.$alertMsg('fail', e.response.data.message || this.$t("pages.error-title"))
      }
      this.loading = false
    }
  }
}
</script>

<style scoped lang="scss">
  .reset-pw {
    color: #20242D;;
    margin-top: 32px;
    &__logo {
      position: fixed;
      z-index: 9999;
      top: 30px;
      left: 50%;
      transform: translateX(-50%);
      img {
        width: 153px;
        height: 48px;
      }
    }
    &__background {
      width: 100vw;
      height: 100vh;
      background: #000000;
    }
    &__title {
      color: #FFFFFF;
      font-weight: 700;
      font-size: 24px;
      line-height: 34px;
      text-align: center;
      margin-bottom: 40px;
    }
    &__password {
      margin-top: 15px;
    }
    &__btn {
      height: 40px;
    }
  }
</style>
