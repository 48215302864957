<template>
  <div class="our-program">
    <div class="container">
      <h2 class="our-program__title">{{ $t("docs.title2") }}</h2>
      <div class="our-program__desc">
        {{ $t("docs.decs2.title") }}
        <ul>
          <li>
            {{ $t("docs.decs2.text1") }}
          </li>
          <li>
            {{ $t("docs.decs2.text2") }}
          </li>
          <li>
            {{ $t("docs.decs2.text3") }}
          </li>
        </ul>
      </div>
      <div class="our-program__commission">
        <p class="box first">
          {{ $t("docs.bonus") }}
        </p>
        <div class="our-program__commission--table">
          <div class="item" v-for="i in 13" :key="i">
            <span class="box text-uppercase">{{
              $t(`packages.name-${i}`)
            }}</span>
            <span class="box">{{ $t(`packages.description-${i}`) }}</span>
          </div>
        </div>
      </div>
      <div class="our-program__commission">
        <p class="box first">
          {{ $t("docs.bonus-pool") }}
        </p>
        <div class="our-program__commission--table">
          <div class="item" v-for="i in 7" :key="i">
            <span class="box text-uppercase">{{
              $t(`title.title-${i + 1}.index`)
            }}</span>
            <span class="box">
              <span class="mb-1 d-block text-left px-2">{{
                $t(`title.title-${i + 1}.bonus`)
              }}</span>
              <span class="mb-1 d-block text-left px-2">{{
                $t(`title.title-${i + 1}.description`)
              }}</span>
              <span
                class="mb-1 d-block text-left px-4"
                v-html="$t(`title.title-${i + 1}.condition`)"
              ></span>
            </span>
          </div>
        </div>
      </div>
      <div class="our-program__note">
        <h4 class="title">
          {{ $t("docs.note.index") }}
        </h4>
        <ul>
          <li>
            <span>
              {{ $t("docs.note.index1") }}
            </span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
.our-program {
  position: relative;
  color: #adaeb2;
   @media only screen and (max-width: 768px) {
    padding: 0 12px;
  }
  .container {
    padding: 64px 0;
    text-align: center;
  }
  &__title {
    color: #ffffff;
    font-family: "Overpass";
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 44px;
  }
  &__desc {
    font-family: "Overpass";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: left;
    color: #adaeb2;
    margin-top: 1rem;
  }
  img {
    margin-top: 40px;
    width: 100%;
    height: auto;
  }
  .img-desc {
    margin-top: 0;
  }
  &__commission {
    margin-top: 35px;
    .box {
      font-family: "Overpass";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      background: #20242d;
      border: 1px solid #43464e;
      padding: 12px 0;
      border-radius: 8px;
      color: #adaeb2;
      @media only screen and (max-width: 768px) {
        font-size: 12px;
        line-height: 14px;
      }
      &.text-uppercase {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .first {
      color: #dbac35;
      margin-bottom: 24px;
    }
    &--table {
      .item {
        display: grid;
        grid-template-columns: 24% 74%;
        justify-content: space-between;
        margin-bottom: 24px;
      }
    }
  }
  &__note {
    text-align: left;
    margin-bottom: 40px;
    .title {
      font-family: "Overpass";
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 36px;
      color: #ffffff;
      margin-bottom: 1rem;
    }
    ol {
      padding-left: 18px;
      span {
        position: relative;
        left: 18px;
      }
    }
  }
}
</style>
