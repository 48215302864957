export const policiesVi = [
    {
        question: "Thu thập thông tin cá nhân của bạn",
        answer: `<p class=MsoNormal><span>Để cung cấp cho bạn tốt hơn các sản phẩm và dịch vụ được đưa ra trên Trang web của chúng tôi, VLINKMETA INC có thể thu thập thông tin nhận dạng cá nhân, chẳng hạn như:</span></p>
        
                    <p class=MsoNormal><span>&nbsp; </span></p>
        
                    <p class=MsoNormal><span>&nbsp;-	Họ và tên</span></p>
        
                    <p class=MsoNormal><span>&nbsp; -	Địa chỉ e-mail</span></p>
         
                    <p class=MsoNormal><span>&nbsp; -	Địa chỉ gửi thư</span></p>
         
                    <p class=MsoNormal><span>&nbsp; -	Số điện thoại</span></p>
         
                    <p class=MsoNormal><span>&nbsp; -	Cấp trên</span></p>
         
                    <p class=MsoNormal><span>&nbsp; -	Chức vụ</span></p>
         
                    <p class=MsoNormal><span>&nbsp; -	Ảnh chứng minh thư</span></p>
        
                    <p class=MsoNormal><span>Nếu bạn mua các sản phẩm và dịch vụ của VLINKMETA INC, chúng tôi sẽ thu thập thông tin thanh toán và thẻ tín dụng. Thông tin này được sử dụng để hoàn tất giao dịch mua.</span></p>
        
                    <p class=MsoNormal><span>VLINKMETA INC cũng có thể thu thập thông tin nhân khẩu học ẩn danh, không đặc biệt đối với bạn, chẳng hạn như:</span></p>
        
                    <p class=MsoNormal><span>&nbsp;-	Tuổi</span></p>
        
                    <p class=MsoNormal><span>&nbsp;-	Giới tính</span></p>
                    <p class=MsoNormal><span>Chúng tôi không thu thập bất kỳ thông tin cá nhân nào về bạn trừ khi bạn tự nguyện cung cấp cho chúng tôi. Tuy nhiên, bạn có thể được yêu cầu cung cấp một số thông tin cá nhân nhất định cho chúng tôi khi bạn chọn sử dụng một số sản phẩm hoặc dịch vụ có sẵn trên trang web. Chúng có thể bao gồm: (a) đăng ký tài khoản trên Trang web của chúng tôi; (b) tham gia rút thăm trúng thưởng hoặc cuộc thi do chúng tôi hoặc một trong các đối tác của chúng tôi tài trợ; (c) đăng ký các ưu đãi đặc biệt từ các bên thứ ba được chọn; (d) gửi cho chúng tôi một tin nhắn email; (e) gửi thẻ tín dụng của bạn hoặc thông tin thanh toán khác khi đặt hàng và mua các sản phẩm và dịch vụ trên trang web của chúng tôi. Nói một cách ngắn gọn, chúng tôi sẽ sử dụng thông tin của bạn không giới hạn cho việc liên lạc với bạn liên quan đến các dịch vụ, sản phẩm bạn đã yêu cầu từ chúng tôi. Chúng tôi cũng có thể thu thập thêm thông tin cá nhân hoặc thông tin phi cá nhân trong tương lai.</span></p>`,
    },
    {
        question: "Sử dụng thông tin cá nhân của bạn",
        answer: `<p class=MsoNormal><span>VLINKMETA INC thu thập và sử dụng thông tin cá nhân của bạn để vận hành một hoặc nhiều trang web của mình và cung cấp các dịch vụ mà bạn đã yêu cầu. </span></p>
                    <p class=MsoNormal><span>VLINKMETA INC cũng có thể sử dụng thông tin nhận dạng cá nhân của bạn để thông báo cho bạn về các sản phẩm hoặc dịch vụ khác có sẵn từ VLINKMETA INC và các chi nhánh. </span></p>`,
    },
    {
        question: "Chia sẻ thông tin với các bên thứ 3",
        answer: `<p class=MsoNormal><span>VLINKMETA INC không bán, thuê hoặc cho thuê danh sách khách hàng của mình cho các bên thứ ba.</span></p>
                <p class=MsoNormal><span>VLINKMETA INC đôi khi có thể liên hệ với bạn thay mặt cho các đối tác kinh doanh bên ngoài về một đề nghị cụ thể mà bạn có thể quan tâm. Trong những trường hợp đó, thông tin nhận dạng cá nhân của bạn (e-mail, tên, địa chỉ, số điện thoại) sẽ được chuyển cho bên thứ ba. VLINKMETA INC có thể chia sẻ dữ liệu với các đối tác đáng tin cậy để giúp thực hiện phân tích thống kê, gửi cho bạn email hoặc thư bưu điện, hỗ trợ khách hàng hoặc sắp xếp việc giao hàng. Tất cả các bên thứ ba đó đều bị cấm sử dụng thông tin cá nhân của bạn ngoại trừ việc cung cấp các dịch vụ này cho VLINKMETA INC và họ được yêu cầu duy trì tính bảo mật thông tin của bạn.</span></p>
        <p class=MsoNormal><span>VLINKMETA INC có thể tiết lộ thông tin cá nhân của bạn mà không cần thông báo, nếu luật pháp yêu cầu làm như vậy hoặc với thiện chí tin rằng hành động đó là cần thiết để: (a) tuân thủ các sắc lệnh của luật hoặc tuân thủ quy trình pháp lý được cung cấp trên VLINKMETA INC hoặc trang web; (b) bảo vệ quyền lợi của VLINKMETA INC; (c) hành động trong những trường hợp cấp thiết để bảo vệ quyền an toàn cá nhân của người dùng VLINKMETA INC, hoặc công chúng.</span></p>`,
    },
    {
        question: "Theo dõi hành vi người dùng",
        answer: `<p class=MsoNormal><span>VLINKMETA INC có thể theo dõi các trang web và các trang mà người dùng của chúng tôi truy cập trong VLINKMETA INC, để xác định dịch vụ nào của VLINKMETA INC là phổ biến nhất. Dữ liệu này được sử dụng để cung cấp nội dung tùy chỉnh và quảng cáo trong VLINKMETA INC cho những khách hàng có hành vi cho thấy họ quan tâm đến một lĩnh vực chủ đề cụ thể.</span></p>`
    },
    {
        question: "Thông tin thu thập tự động",
        answer: `<p class=MsoNormal><span>IThông tin về phần cứng và phần mềm máy tính của bạn có thể được VLINKMETA INC tự động thu thập. Thông tin này có thể bao gồm: địa chỉ IP, loại trình duyệt, tên miền, thời gian truy cập và địa chỉ trang web giới thiệu. Thông tin này được sử dụng cho hoạt động của dịch vụ, để duy trì chất lượng của dịch vụ và cung cấp số liệu thống kê chung về việc sử dụng trang web VLINKMETA INC.</span></p>`,
    },
    {
        question: "Các liên kết",
        answer: `<p class=MsoNormal><span>Trang web này chứa đường dẫn tới những trang khác. Xin lưu ý rằng chúng tôi không chịu trách nhiệm về nội dung hoặc cách thức bảo mật của các trang web khác nêu trên. Chúng tôi khuyến khích người dùng của mình lưu ý khi họ rời khỏi trang web của chúng tôi và đọc các điều khoản về quyền riêng tư của bất kỳ trang web nào khác thu thập thông tin nhận dạng cá nhân.</span></p>`,
    },
    {
        question: "Trẻ em dưới 13 tuổi",
        answer: ` <p class=MsoNormal><span>VLINKMETA INC không chủ ý thu thập thông tin nhận dạng cá nhân từ trẻ em dưới mười ba tuổi. Nếu bạn dưới mười ba tuổi, bạn phải xin phép cha mẹ hoặc người giám hộ của bạn để sử dụng trang web này.</span></p>`
    },
    {
        question: "Chọn không tham gia & hủy đăng ký nhận các thông tin liên lạc của bên thứ ba",
        answer: ` <p class=MsoNormal><span>Chúng tôi tôn trọng quyền riêng tư của bạn và cho bạn cơ hội từ chối nhận thông báo về một số thông tin nhất định. Người dùng có thể chọn không nhận bất kỳ hoặc tất cả thông tin liên lạc từ các đối tác bên thứ ba của VLINKMETA INC bằng cách liên hệ với chúng tôi tại đây:</span></p>
        
                    <p class=MsoNormal><span>-- Trang web: _________________ </span></p>
        
                    <p class=MsoNormal><span>- Email:  support@vlinkgroup.net </span></p>
        
                    <p class=MsoNormal><span>- Số điện thoại: 18882999888 </span></p>`
    },
    {
        question: "Liên lạc qua E-mail",
        answer: `<p class=MsoNormal><span>Đôi khi, VLINKMETA INC có thể liên hệ với bạn qua email với mục đích cung cấp thông báo, khuyến mại, cảnh báo, xác nhận, khảo sát và thông tin liên lạc chung khác. Để cải thiện Dịch vụ của mình, chúng tôi có thể nhận được thông báo khi bạn mở email từ VLINKMETA INC hoặc nhấp vào một liên kết trong đó. </span></p>`
    },
    {
        question: "Những thay đổi với điều khoản này",
        answer: `<p class=MsoNormal><span>VLINKMETA INC có quyền thay đổi Chính sách Bảo mật này theo thời gian. Chúng tôi sẽ thông báo cho bạn về những thay đổi quan trọng trong cách chúng tôi xử lý thông tin cá nhân bằng cách gửi thông báo đến địa chỉ email chính được chỉ định trong tài khoản của bạn, bằng cách đặt một thông báo nổi bật trên trang web của chúng tôi hoặc (và) cập nhật bất kỳ thông tin bảo mật nào trên trang này. Việc bạn tiếp tục sử dụng trang web hoặc (và) dịch vụ có sẵn thông qua trang web này sau những sửa đổi đó sẽ cấu thành: (a) sự công nhận của bạn về chính sách quyền riêng tư đã sửa đổi; và (b) đồng ý tuân theo và bị ràng buộc bởi chính sách đó.</span></p>
        `
    },
    {
        question: "Thông tin liên hệ",
        answer: `<p class=MsoNormal><span>VLINKMETA INC hoan nghênh các câu hỏi hoặc nhận xét của bạn về Chính sách về Quyền riêng tư này. Nếu bạn tin rằng VLINKMETA INC đã không tuân thủ chính sách này, vui lòng liên hệ với VLINKMETA INC tại: </span></p>        
            <p class=MsoNormal><span>VLINKMETA INC </span></p>
            <p class=MsoNormal><span>5444 WESTHEIMER RD SUITE 1000, HOUSTON, Texas 77056</span></p>
            <p class=MsoNormal><span>Email Address: support@vlinkgroup.net </span></p>
            <p class=MsoNormal><span>Telephone number: 18882999888</span></p>
            <p class=MsoNormal><span>Có hiệu lực từ ngày 1 tháng 5 năm 2019</span></p>
        `
    }
]

export const policiesEn = [
    {
        question: "Collection of your Personal Information",
        answer: `<p class=MsoNormal><span lang=EN-US>In order to better provide you with
        products and services offered on our Site, VLINKGROUP INC may collect
        personally identifiable information, such as your: </span></p>
        
                    <p class=MsoNormal><span lang=EN-US>&nbsp; </span></p>
        
                    <p class=MsoNormal><span lang=EN-US>&nbsp;-          First and Last Name </span></p>
        
                    <p class=MsoNormal><span lang=EN-US>&nbsp;-          Mailing Address </span></p>
        
                    <p class=MsoNormal><span lang=EN-US>&nbsp;-          E-mail Address </span></p>
        
                    <p class=MsoNormal><span lang=EN-US>&nbsp;-          Phone Number </span></p>
        
                    <p class=MsoNormal><span lang=EN-US>&nbsp;-          Employer </span></p>
        
                    <p class=MsoNormal><span lang=EN-US>&nbsp;-          Job Title </span></p>
        
                    <p class=MsoNormal><span lang=EN-US>&nbsp;-          ID photo </span></p>
        
                    <p class=MsoNormal><span lang=EN-US>&nbsp; </span></p>
        
                    <p class=MsoNormal><span lang=EN-US>If you purchase VLINKGROUP INC's products
        and services, we collect billing and credit card information. This information
        is used to complete the purchase transaction. </span></p>
        
                    <p class=MsoNormal><span lang=EN-US>&nbsp; </span></p>
        
                    <p class=MsoNormal><span lang=EN-US>VLINKGROUP INC may also collect anonymous
        demographic information, which is not unique to you, such as your: </span></p>
        
                    <p class=MsoNormal><span lang=EN-US>&nbsp; </span></p>
        
                    <p class=MsoNormal><span lang=EN-US>&nbsp;-          Age </span></p>
        
                    <p class=MsoNormal><span lang=EN-US>&nbsp;-          Gender </span></p>
                    <p class=MsoNormal><span lang=EN-US>We do not collect any personal information
        about you unless you voluntarily provide it to us. However, you may be required
        to provide certain personal information to us when you elect to use certain
        products or services available on the Site. These may include: (a) registering
        for an account on our Site; (b) entering a sweepstakes or contest sponsored by
        us or one of our partners; (c) signing up for special offers from selected
        third parties; (d) sending us an email message; (e) submitting your credit card
        or other payment information when ordering and purchasing products and services
        on our Site. To wit, we will use your information for, but not limited to,
        communicating with you in relation to services and/or products you have
        requested from us. We also may gather additional personal or non-personal
        information in the future. </span></p>`,
    },
    {
        question: "Use of your Personal Information ",
        answer: `<p class=MsoNormal><span lang=EN-US>VLINKGROUP INC collects and uses your
        personal information to operate its website(s) and deliver the services you
        have requested. </span></p>
                    <p class=MsoNormal><span lang=EN-US>VLINKGROUP INC may also use your personally
        identifiable information to inform you of other products or services available
        from VLINKGROUP INC and its affiliates. </span></p>`,
    },
    {
        question: "Sharing Information with Third Parties",
        answer: `<p class=MsoNormal><span lang=EN-US>VLINKGROUP INC does not sell, rent or lease
        its customer lists to third parties. </span></p>
        
                    <p class=MsoNormal><span lang=EN-US>&nbsp; </span></p>
        
                    <p class=MsoNormal><span lang=EN-US>VLINKGROUP INC may, from time to time,
        contact you on behalf of external business partners about a particular offering
        that may be of interest to you. In those cases, your unique personally
        identifiable information (e-mail, name, address, telephone number) is
        transferred to the third party. VLINKGROUP INC may shares data with trusted
        partners to help perform statistical analysis, send you email or postal mail,
        provide customer support, or arrange for deliveries. All such third parties are
        prohibited from using your personal information except to provide these
        services to VLINKGROUP INC, and they are required to maintain the
        confidentiality of your information. </span></p>
        <p class=MsoNormal><span lang=EN-US>VLINKGROUP INC may disclose your personal
        information, without notice, if required to do so by law or in the good faith
        belief that such action is necessary to: (a) conform to the edicts of the law
        or comply with legal process served on VLINKGROUP INC or the site; (b) protect
        and defend the rights or property of VLINKGROUP INC; and/or (c) act under
        exigent circumstances to protect the personal safety of users of VLINKGROUP
        INC, or the public. </span></p>`,
    },
    {
        question: "Tracking User Behavior",
        answer: `<p class=MsoNormal><span lang=EN-US>VLINKGROUP INC may keep track of the
        websites and pages our users visit within VLINKGROUP INC, in order to determine
        what VLINKGROUP INC services are the most popular. This data is used to deliver
        customized content and advertising within VLINKGROUP INC to customers whose
        behavior indicates that they are interested in a particular subject area. </span></p>`
    },
    {
        question: "Automatically Collected Information",
        answer: `<p class=MsoNormal><span lang=EN-US>Information about your computer hardware
        and software may be automatically collected by VLINKGROUP INC. This information
        can include: your IP address, browser type, domain names, access times and
        referring website addresses. This information is used for the operation of the
        service, to maintain quality of the service, and to provide general statistics regarding
        use of the VLINKGROUP INC website. </span></p>`,
    },
    {
        question: "Links",
        answer: `<p class=MsoNormal><span lang=EN-US>This website contains links to other sites.
        Please be aware that we are not responsible for the content or privacy
        practices of such other sites. We encourage our users to be aware when they
        leave our site and to read the privacy statements of any other site that
        collects personally identifiable information. </span></p>`,
    },
    {
        question: "Children Under Thirteen",
        answer: ` <p class=MsoNormal><span lang=EN-US>VLINKGROUP INC does not knowingly collect
        personally identifiable information from children under the age of thirteen. If
        you are under the age of thirteen, you must ask your parent or guardian for
        permission to use this website. </span></p>`
    },
    {
        question: "Opt-Out &amp; Unsubscribe from Third Party Communications",
        answer: ` <p class=MsoNormal><span lang=EN-US>We respect your privacy and give you an
        opportunity to opt-out of receiving announcements of certain information. Users
        may opt-out of receiving any or all communications from third-party partners of
        VLINKGROUP INC by contacting us here: </span></p>
        
                    <p class=MsoNormal><span lang=EN-US>- Web page: _________________ </span></p>
        
                    <p class=MsoNormal><span lang=EN-US>- Email: info@vlinkgroup.net </span></p>
        
                    <p class=MsoNormal><span lang=EN-US>- Phone: 18882999888 </span></p>`
    },
    {
        question: "E-mail Communications",
        answer: `<p class=MsoNormal><span lang=EN-US>From time to time, VLINKGROUP INC may
        contact you via email for the purpose of providing announcements, promotional
        offers, alerts, confirmations, surveys, and/or other general communication. In
        order to improve our Services, we may receive a notification when you open an
        email from VLINKGROUP INC or click on a link therein. </span></p>`
    },
    {
        question: "Changes to this Statement",
        answer: `<p class=MsoNormal><span lang=EN-US>VLINKGROUP INC reserves the right to change
        this Privacy Policy from time to time. We will notify you about significant
        changes in the way we treat personal information by sending a notice to the
        primary email address specified in your account, by placing a prominent notice
        on our site, and/or by updating any privacy information on this page. Your
        continued use of the Site and/or Services available through this Site after
        such modifications will constitute your: (a) acknowledgment of the modified
        Privacy Policy; and (b) agreement to abide and be bound by that Policy. </span></p>
        `
    },
    {
        question: "Contact Information",
        answer: `<p class=MsoNormal><span lang=EN-US>VLINKGROUP INC welcomes your questions or
        comments regarding this Statement of Privacy. If you believe that VLINKGROUP
        INC has not adhered to this Statement, please contact VLINKGROUP INC at: </span></p>
        
                    <p class=MsoNormal><span lang=EN-US>&nbsp; </span></p>
        
                    <p class=MsoNormal><span lang=EN-US>VLINKGROUP INC </span></p>
        
                    <p class=MsoNormal><span lang=EN-US>5444 WESTHEIMER RD SUITE 1000 </span></p>
        
                    <p class=MsoNormal><span lang=EN-US>HOUSTON, Texas 77056 </span></p>
        
                    <p class=MsoNormal><span lang=EN-US>&nbsp; </span></p>
        
                    <p class=MsoNormal><span lang=EN-US>Email Address: </span></p>
        
                    <p class=MsoNormal><span lang=EN-US>info@vlinkgroup.net </span></p>
        
                    <p class=MsoNormal><span lang=EN-US>&nbsp; </span></p>
        
                    <p class=MsoNormal><span lang=EN-US>Telephone number: </span></p>
        
                    <p class=MsoNormal><span lang=EN-US>18882999888 </span></p>
        
                    <p class=MsoNormal><span lang=EN-US>&nbsp; </span></p>
        
                    <p class=MsoNormal><span lang=EN-US>Effective as of May 01, 2019 </span></p>
        `
    }
]

