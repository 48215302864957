<template>
  <div class="body">
    <news />
  </div>
</template>

<script>
export default {
  components: {
    news: () => import("@containers/News/news.vue"),
  },
};
</script>

<style scoped lang="scss">
.body {
  background: #141822;
}
</style>
