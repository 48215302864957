<template>
  <div class="body">
    <contact />
  </div>
</template>

<script>
export default {
  components: {
    contact: () => import("@containers/Contact/index.vue"),
  },
};
</script>

<style scoped lang="scss">
.body {
  background: #141822;
}
</style>
