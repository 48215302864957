<template>
  <div class="wallets-wrap p-3">
    <div class="wallets__title">
      <h2 class="text-white">{{ $t("titles.my-wallet") }}</h2>
      <div class="wallets__action">
        <button class="btn btn-primary btn-create-qr"
          @click="onOpenModal(MODAL.CONVERT_TO_VMM)">
          {{ $t("action.convert") }} VMM
        </button>
        <button
          v-if="isBussinessType"
          class="btn btn-primary ml-2"
          @click="onOpenModal(MODAL.SWAP)"
        >
          {{ $t("action.swap") }}
        </button>
        <template  v-if="$device.isDesktop">
          <button
            v-if="currentUser.AllowCreateQr"
            class="btn btn-primary btn-create-qr mx-2"
            @click="onOpenModal(MODAL.CREATE_QR)"
          >
            {{ $t("wallet.create-qr-transfer") }}
          </button>
          <button
            class="btn btn-primary btn-transfer"
            @click="onOpenModal(MODAL.TRANSFER_HOME)"
          >
            {{ $t("transfer.index") }}
          </button>
      </template>
      </div>
    </div>
    <div v-if="!isLoading" class="wallets__table border-radius-2">
      <b-card class="border-radius-2">
        <!-- <div class="row wallets__balance w-auto">
          <div class="col-12 col-md-9">
            <div class="wallets__balance--title">
              <span>{{ $t("wallet.total-balance") }}</span>
              <i
                :class="[
                  isShowWallet ? 'fa fa-eye' : 'fa fa-eye-slash',
                  'icon eye',
                ]"
                @click="showHideWallet"
              ></i>
            </div>
            <div class="wallets__balance--value" v-if="walletValue">
              <span
                >{{
                  isShowWallet
                    ? $options.filters.toCurrency(walletValue.WalletTotal, true)
                    : "********"
                }}
              </span>
            </div>
          </div>
          <div class="col-12 col-md-3 d-flex align-items-start flex-column">
            <span>{{ $t("wallet.filter") }}</span>
            <b-input-group>
              <b-form-input
                style="padding-right: 32px"
                :placeholder="$t('wallet.enter-wallet')"
                v-model="walletName"
                type="text"
                autocomplete="off"
                width="100%"
              />
              <img
                v-if="walletName"
                src="/img/icons/icon-close-black.svg"
                alt="icon-close"
                class="icon-close"
                @click="removeSearch"
              />
            </b-input-group>
          </div>
        </div> -->
        <!-- <div class="wallets__table-title">
          <span>{{ $t("titles.my-wallet") }}</span>
        </div> -->
        <div class="wallets__table-list mt-4">
          <table>
            <thead>
              <tr>
                <th>Token</th>
                <th>{{ $t("wallet.avaible-balance") }}</th>
                <th>USD</th>
                <th v-if="$device.isDesktop">{{ $t("wallet.action") }}</th>
              </tr>
            </thead>
            <tbody>
              <template v-if="walletAvailability && walletAvailability.length">
                <template v-for="(item, index) in walletAvailability" >
                  <tr v-if="item.value !== 'AirdropBlock'"  :key="index">
                    <td @click="openDetail(item.value)" class="token-item">
                      <img class="img-icon_wallet mr-2" :src="item.icon" alt="" />
                      <span role="button">{{ item.label }}</span>
                    </td>
                    <template v-if="walletValue[item.value]">
                      <td>
                      <span v-if="walletValue[item.value]">
                        <template v-if="!isShowWallet">********</template>
                        <template v-else>
                          {{
                            walletValue[item.value].Amount | toCryptocurrency
                          }}
                        </template>
                      </span>
                      </td>
                      <td>
                      <span v-if="item.toFiat && walletValue[item.value]">
                        <template v-if="!isShowWallet">********</template>
                        <template v-else>
                          {{
                            (walletValue[item.value].Amount *
                              (walletValue[item.value].CurrentPrice || 1))
                              | toCurrency(true)
                          }}
                        </template>
                      </span>
                      </td>
                      <td v-if="$device.isDesktop">
                        <div class="wallet-action">
                          <a
                            v-if="
                            [
                              'VLGTokenAvailable',
                              'VLGCash',
                              'VLGPlus',
                            ].includes(item.value)
                          "
                            class="pr-2"
                            @click="onOpenModal(MODAL.TRANSFER_HOME, item.value)"
                          >{{ $t("transfer.index") }}</a
                          >
                          <a @click="openDetail(item.value)" class="pl-2">{{
                              $t("wallet.detail")
                            }}</a>
                        </div>
                      </td>
                    </template>
                    <template v-else>
                      <td v-for="n in 2" :key="n"><span>-</span></td>
                      <td v-if="$device.isDesktop">
                        <div class="wallet-action">
                          <!-- <a
                            @click="onOpenModal(MODAL.TRANSFER_HOME)"
                            class="pr-2"
                            >{{ $t("transfer.index") }}</a
                          > -->
                          <a @click="openDetail(item.value)" class="pl-2">{{
                              $t("wallet.detail")
                            }}</a>
                        </div>
                      </td>
                    </template>
                  </tr>
                </template>
              </template>
              <template v-else>
                <tr>
                  <td colspan="5" class="text-center">
                    <span>
                      {{ $t("wallet.no-data") }}
                    </span>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </b-card>
    </div>
    <div v-else class="container position-relative mt-4" >
      <BaseLoading v-if="isLoading" class="loading" :loading="isLoading" />
    </div>
    <ModalTransfer :walletType="walletType" @refresh="onRefresh()" />
    <ModalSwap  @refresh="onRefresh()" />
    <ModalCreateQr />
    <!-- <ModalWithdraw /> -->
    <!-- <ModalCertificate /> -->
    <!-- <ModalConvertToCash />
    <ModalCreateLending @refresh="onRefresh()" /> -->
    <!-- <ModalTransferTron /> -->
    <ModalConvertToVMM @refresh="onRefresh()" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import wallet from "@/constants/wallet";
import ModalCreateQr from "@/containers/Wallet/modal-create-qr.vue";
import ModalTransfer from "@/containers/Wallet/modal-transfer-home.vue";
import ModalConvertToVMM from "@/containers/Wallet/modal-convert-to-vmm.vue";
import { MODAL } from "@/constants";
import BaseLoading from "@components/Inputs/BaseLoading";
export default {
  components: {
    BaseLoading,
    ModalTransfer,
    ModalSwap: () => import("@/containers/Wallet/modal-swap.vue"),
    ModalConvertToVMM,
    ModalCreateQr,
  },
  data() {
    return {
      wallet,
      walletName: "",
      walletType: "",
      isShowWallet: false,
      isLoading: false,
      MODAL,
    };
  },
  created() {
    if (localStorage.getItem("showWallet")) {
      this.isShowWallet = true;
    }
  },
  methods: {
    ...mapActions(["fetchWallet"]),
    openDetail(id) {
      this.$router.push(`/wallet/detail?code=${id}`);
    },
    openDetailTron() {
      this.$router.push(`/wallet/detail-tron`);
    },
    onRefresh() {
      this.fetchWallet();
      this.closeAllModal();
    },
    onOpenModal(id, walletType = null) {
      this.walletType = walletType;
      this.$bvModal.show(id);
    },
    closeAllModal() {
      const self = this;
      Object.values(this.MODAL).forEach(function (item) {
        self.$bvModal.hide(item);
      });
    },
    removeSearch() {
      this.walletName = "";
    },
    showHideWallet() {
      if (this.isShowWallet) {
        this.isShowWallet = false;
        localStorage.removeItem("showWallet");
      } else {
        this.isShowWallet = true;
        localStorage.setItem("showWallet", true);
      }
    },
  },
  computed: {
    ...mapGetters({
      walletValue: "getWallet",
    }),
    ...mapGetters('Auth', {currentUser: 'currentUser'}),
    isBussinessType() {
      return this.currentUser.Type === "BUSINESS";
    },
     walletAvailability() {
      return !this.walletName
        ? wallet.filter(item=> {
          return !['3EB','5EB', '1L'].includes(item.label)
        })
        :  wallet.filter(item=> {
          return !['3EB','5EB', '1L'].includes(item.label)
        }).filter((elm) =>
            elm.label.toLowerCase().includes(this.walletName.toLowerCase())
          );
    },
  },
  async mounted() {
    this.isLoading = true
    this.fetchWallet();
    setTimeout(() => {
      this.isLoading = false
    }, 2000)
    await this.$nextTick()
    if (this.$route.query.modalOpen == 'VMM_CONVERT') {
      this.$bvModal.show(MODAL.CONVERT_TO_VMM);
    }
  },
};
</script>

<style scoped lang="scss">
.wallets {
  margin-top: 24px;
  &__title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 17px;
    h2 {
      font-weight: 700;
      font-size: 24px;
      line-height: 34px;
      color: #141822;
    }
    .btn {
      width: 240px;
    }
    .btn-create-qr {
      color: #007bff;
      background: #ffffff;
      border: 1px solid #007bff;
      &:hover {
        background: #007bff;
        color: #ffffff;
      }
    }
    @media only screen and (max-width: 920px) {
      flex-direction: column;
      h2 {
        margin-bottom: 16px;
      }
      .wallets__action {
        .btn-create-qr,
        .btn {
          width: 100%;
        }
        .btn {
          margin-bottom: 16px;
        }
      }
    }
    @media only screen and (max-width: 768px) {
      .wallets__action {
        flex-direction: column;
      }
    }
  }
  &__action {
    display: flex;
    justify-content: flex-end;
    align-content: center;
  }
  &__table {
    &-list {
      overflow-x: auto;
      overflow-y: hidden;
      table {
        width: 100%;
        thead {
          th {
            padding: 10px;
            background: rgba(173, 174, 178, 0.2);
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            color: #898b91;
            vertical-align: middle;
            &:last-child {
              text-align: right;
            }
            @media only screen and (max-width: 768px) {
              &:nth-child(2),
              &:nth-child(3) {
                max-width: 21.333vw;
              }
            }
          }
        }
        tr {
          border-bottom: 1px solid #eaeaea;
        }
        td {
          &.token-item {
            cursor: pointer;
          }
          padding: 15px 10px;
          vertical-align: middle;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #141822;
          img {
            width: 50px;
            height: 50px;
            object-fit: contain;
          }
          &.in-progress {
            color: #67b231;
            text-transform: capitalize;
          }
          &.active-date,
          &.status {
            text-transform: capitalize;
          }
          .pr-2 {
            border-right: 1px solid #007bff;
          }
          a {
            color: #007bff !important;
            cursor: pointer;
            &:hover {
              text-decoration: underline;
            }
          }
          &:last-child {
            width: 30%;
            text-align: right;
          }
          @media only screen and (max-width: 768px) {
            padding: 15px 5px;
            &:first-child {
              display: flex;
              img {
                width: 20px;
                height: 20px;
              }
              span {
                font-weight: 600;
                font-size: 12px;
                line-height: 20px;
                color: #141822;
              }
            }
            &:nth-child(2),
            &:nth-child(3) {
              white-space: nowrap;
              font-size: 12px;
              line-height: 20px;
              color: #141822;
              max-width: 46.667vw;
            }
          }
        }
      }
    }
  }
  &__balance {
    width: 100%;
    padding-bottom: 23px;
    border-bottom: 1px solid #eaeaea;
    &--title {
      span {
        color: #898b91;
        font-weight: 400;
        font-size: 18px;
        line-height: 26px;
      }
      i {
        margin-left: 16px;
        color: #898b91;
      }
    }
    &--value {
      span {
        font-weight: 700;
        font-size: 24px;
        line-height: 34px;
        color: #141822;
        margin-top: 9px;
      }
    }
  }
  &__table-title {
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    color: #43464e;
    margin: 24px 0;
  }
}
.icon-close {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  z-index: 99;
  &:hover {
    cursor: pointer;
  }
}
</style>
