<template lang="">
  <div class="convert">
    <div class="convert__title">{{ $t("action.convert") }} VMM</div>
    <div class="convert__content">
      <div class="block-bg">
        <BaseInputCustomWallet
          class-name="theme-light"
          ref="amount"
          v-model="amount"
          type="text"
          name="amount"
          required
          placeholder="0.00"
          :label="$t('receive.vlg')"
          thousands-separator-symbol=","
          :balance="
            $options.filters.formatNumber(walletValue.VLGTokenAvailable.Amount)
          "
          :maxValue="
            $options.filters.formatNumber(walletValue.VLGTokenAvailable.Amount)
          "
          @input="debounceInput"
          :error-required="$t('validate.required', [$t('receive.vlg')])"
        />
      </div>
      <hr />
      <div class="block-bg">
        <BaseInputCustomPin
          class-name="theme-light"
          ref="pin"
          v-model="block"
          :label="$t('receive.vmmblock')"
          disabled
        />
        <BaseInputCustomPin
          class-name="theme-light"
          ref="pin"
          v-model="burn"
          :label="$t('receive.vmmburn')"
          disabled
        />
      </div>
    </div>
    <hr />
    <div class="block-bg">
      <p>{{ $t("rate") }}</p>
      <div class="d-flex justify-content-around">
        <p>1VMM = ${{ walletValue.VmmToken.CurrentPrice }}</p>
        <p>1VLG = ${{ walletValue.VLGTokenAvailable.CurrentPrice }}</p>
      </div>
    </div>
    <BaseInputCustomPin
      class-name="theme-light mt-3"
      ref="pin"
      v-model="pin"
      required
      name="pin"
      :isPin="true"
      :label="$t('input.pin_uppercase')"
    />

    <p class="text-note">
      {{ $t("stock.pin.first") }}
      <router-link
        tag="a"
        :to="'/profile/security?tab=pin'"
        target="_blank"
        style="color: #007bff; font-weight: bold"
      >
        {{ $t("stock.pin.second") }}
      </router-link>
      {{ $t("stock.pin.third") }}
    </p>
    <div class="convert__action">
      <button
        class="btn btn-primary btn-round convert__btn-cancel"
        @click="$emit('cancel')"
      >
        {{ $t("action.cancel") }}
      </button>
      <button
        :disabled="!pin"
        class="btn btn-primary"
        @click="submit"
        :class="{ 'button--loading': loading }"
      >
        <span>{{ $t("action.convert") }}</span>
      </button>
    </div>
  </div>
</template>
<script>
import { NOTIFICATION_ICON } from "@/constants";
import BaseInputCustom from "@components/Inputs/BaseInputCustomWallet";
import validateMixin from "@/core/mixins/validate";
import { RepositoryFactory } from "@apis/repositoryFactory";
const WalletRepository = RepositoryFactory.get("wallet");
import { mapGetters } from "vuex";
import BaseInputCustomPin from "@components/Inputs/BaseInputCustom";
import BaseSelect from "@/components/Inputs/BaseSelect.vue";
import BaseInputCustomWallet from "@components/Inputs/BaseInputCustomWallet";
import debounce from "lodash.debounce";

export default {
  components: {
    BaseSelect,
    BaseInputCustom,
    BaseInputCustomPin,
    BaseInputCustomWallet,
  },
  mixins: [validateMixin],
  data() {
    return {
      amountType: ["VLG Token", "VLG Token Staking"],
      amount: "",
      burn: "",
      block: "",
      loading: false,
      pin: "",
      type: "VLG Token",
      VLGTokenAvailableAmount: 0,
    };
  },
  computed: {
    ...mapGetters({
      walletValue: "getWallet",
    }),
  },
  mounted() {
    this.fetchValue(0);
  },
  methods: {
    debounceInput: debounce(function (e) {
      this.fetchValue(this.amount);
    }, 500),
    async fetchValue(value) {
      this.block = value * 0.1;
      this.burn = value * 0.9;

      const rateVmm = this.walletValue.VmmToken.CurrentPrice;
      const rateVlg = this.walletValue.VLGTokenAvailable.CurrentPrice;
      const rateVLGVMM = rateVlg / rateVmm;

      this.block = this.$options.filters.toCryptocurrency(
        this.block * rateVLGVMM
      );
      this.burn = this.$options.filters.toCryptocurrency(
        this.burn * rateVLGVMM
      );
      // this.amount = this.$options.filters.toCryptocurrency(value);
    },
    async submit() {
      const refs = ["pin"];
      const refsValid = this.$_validateMixin_refs(refs);
      if (refsValid) {
        this.loading = true;
        try {
          const response = await WalletRepository.convertVLGToVMM(
            this.pin,
            this.amount
          );
          this.$notifyPopup(
            NOTIFICATION_ICON.SUCCESS,
            this.$t("swal.title.success"),
            this.$t("conver-vmm-success"),
            {
              action1Text: "OK",
              action1Func: () => ({}),
            }
          );
          this.$emit("refresh");
          this.$emit("cancel");
        } catch (error) {
          this.$notifyPopup(
            NOTIFICATION_ICON.ERROR,
            this.$t("swal.title.fail"),
            error.response.data.message,
            {
              action1Text: this.$t("action.try"),
              action1Func: () => ({}),
            }
          );
        }
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.convert {
  &__title {
    font-family: "Overpass";
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 44px;
    text-align: center;
    color: #141822;
    margin-bottom: 32px;
  }

  &__password {
    margin-top: 15px;
  }

  &__btn {
    height: 40px;
  }

  &__action {
    display: grid;
    grid-template-columns: 47% 47%;
    justify-content: space-between;
  }

  &__btn-cancel {
    color: #007bff;
    background: #ffffff;
    border: 1px solid #007bff;

    &:hover {
      background: #007bff;
      color: #ffffff;
    }
  }

  .text-note {
    font-size: 14px;
    line-height: 20px;
    color: #898b91;
  }
}

::v-deep .eye {
  color: #43464e !important;
}

::v-deep .base-select {
  margin-bottom: 20px;

  &__label {
    color: #43464e;
  }
}

::v-deep .base-select__wrap {
  background: #f7f7f7;

  .arrow-down {
    color: #43464e;
  }
}

::v-deep .base-select__inner {
  p {
    color: #898b91;
  }
}

.block-bg {
  background: aliceblue;
  padding: 8px;
  border-radius: 8px;
}
</style>
