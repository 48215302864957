<template>
  <div class="contact">
    <div class="container">
      <h2 class="contact__title">{{ $t('contact.index') }}</h2>
      <div class="contact__content">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3464.3370997124634!2d-95.47573778453352!3d29.738955681994454!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640c163e60277a5%3A0xb5a2d41edbec26c9!2s5444%20Westheimer%20Rd%20Suite%201000%2C%20Houston%2C%20TX%2077056%2C%20USA!5e0!3m2!1sen!2s!4v1661497065826!5m2!1sen!2s" width="100%" height="400" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        <div class="help">
          <p class="title">{{ $t('contact.title') }}</p>
          <p class="description">{{ $t('contact.sub') }}</p>
        </div>
        <div class="form-contact">
          <BaseInputCustom
            class-name="contact__name"
            ref="name"
            v-model="formData.Name"
            type="text"
            name="name"
            required
            :label="$t('contact.full-name')"
            :error-required="$t('validate.required', [$t('contact.full-name')])"
          />
          <BaseInputCustom
            class-name="contact__username"
            ref="username"
            v-model="formData.Username"
            type="text"
            name="username"
            required
            :label="$t('contact.Username')"
            :error-required="$t('validate.required', [$t('contact.Username')])"
          />
          <BaseInputCustom
            class-name="contact__email"
            ref="email"
            v-model="formData.Email"
            type="email"
            name="email"
            required
            :label="$t('contact.email')"
            error-email="Email is not valid!"
            :error-required="$t('validate.required', [$t('contact.email')])"
          />
           <BaseSelect
            ref="type"
            :input-value="formData.Type"
            name="type"
            :label="$t('contact.type')"
            :items="options"
            text-field="text"
            value-field="value"
            :is-show-default="false"
            required
            classLabel="text-left"
            :error-required="$t('validate.required', [$t('contact.issue')])"
            key-loop="index"
            @change="formData.Type = $event"
          />
          <div class="form-group contact__description mt-3">
            <label>{{ $t('contact.content') }}</label>
            <textarea
              :class="{'area-error': errorContent }"
              class="form-control"
              v-model="formData.Content"
              id="exampleFormControlTextarea1"
              rows="3">
            </textarea>
          </div>
          <p v-if="errorContent" class="error">
            {{ $t('validate.required', [$t('contact.content')]) }}
          </p>
          <button type="submit" class="btn btn-primary"
            :class="{'button--loading': loading}"
            @click="submit"
          >
          <span>{{$t('forms.submit')}}</span></button>
        </div>
        <div class="contact-icon">
          <div class="item">
            <a href="https://t.me/VlgCustomerSupport" class="item__link" target="_blank">
              <img src="/img/contact/telegram.webp" alt="telegram">
              <div class="text">
                <span>Telegram:</span>
                <span>@VlgCustomerSupport</span>
              </div>
            </a>
          </div>
          <div class="item d-none d-md-block">
            <a href="https://g.page/Capital-One-Building-3855?share" class="item__link" target="_blank">
              <img src="/img/contact/address.webp" alt="address">
              <div class="text">
                <span>Address:</span>
                <span>5444 Westheimer Rd, Suite 1000, Houston Texas 77056 USA</span>
              </div>
            </a>
          </div>
          <div class="item">
            <a href="mailto:support@vlinkgroup.net" class="item__link" target="_blank">
              <img src="/img/contact/mail.webp" alt="telegram">
              <div class="text">
                <span>Email:</span>
                <span>support@vlinkgroup.net</span>
              </div>
            </a>
          </div>
          <div class="item d-block d-md-none">
            <a href="https://g.page/Capital-One-Building-3855?share" class="item__link" target="_blank">
              <img src="/img/contact/address.webp" alt="address">
              <div class="text">
                <span>Address:</span>
                <span>5444 Westheimer Rd, Suite 1000, Houston Texas 77056 USA</span>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { RepositoryFactory } from "@apis/repositoryFactory";
const AssetRepository = RepositoryFactory.get("user");
import {MODAL, NOTIFICATION_ICON} from "@/constants";
import BaseSelect from "@/components/Inputs/BaseSelect.vue"
import BaseInputCustom from "@/components/Inputs/BaseInputCustom.vue"
import validateMixin from '@/core/mixins/validate'
import isEmpty from "lodash/isEmpty"
export default {
  components: {
    BaseSelect,
    BaseInputCustom
  },
  mixins: [validateMixin],
  data() {
    return {
      loading: false,
      data: {},
      formData: {
        Name: '',
        Username: '',
        Email:  '',
        Type: '',
        Content: ''
      },
      isValid: true,
      errorContent: false,
      options: [
        { value: this.$t('issues.login'), text: this.$t('issues.login')},
        { value: this.$t('issues.forgot'), text: this.$t('issues.forgot')},
        { value: this.$t('issues.transfer'), text: this.$t('issues.transfer')},
        { value: this.$t('issues.other'), text: this.$t('issues.other')},
      ],
    }
  },
  watch: {
    'formData.Content' (value) {
      value  ? this.errorContent = false : this.errorContent = true
    }
  },
  created() {
    if (!isEmpty(this.$store.getters["Auth/currentUser"])) {
      this.formData.Name = this.$store.getters["Auth/currentUser"].FirstName + ' ' + this.$store.getters["Auth/currentUser"].LastName
      this.formData.Username = this.$store.getters["Auth/currentUser"].Username
      this.formData.Email = this.$store.getters["Auth/currentUser"].Email
    }
  },
  methods: {
    async submit () {
      if (isEmpty(this.$store.getters["Auth/currentUser"])) {
        this.openLoginModal()
        return
      }
      const refs = ['name', 'username', 'email', 'type']
      const refsValid = this.$_validateMixin_refs(refs)
      this.formData.Content ? this.errorContent = false : this.errorContent = true
      if (refsValid && !this.errorContent) {
        this.loading = true
        const response = await AssetRepository.sendContactForm(this.formData)
        if (response) {
          this.$notifyPopup(NOTIFICATION_ICON.SUCCESS,
            this.$t('swal.title.submit'),
            response.message,
            {
              action1Text: 'OK',
              action1Func: 'OK'
            }
          )
        }
        this.loading = false
      }
    },
    openLoginModal() {
      this.$bvModal.show(MODAL.LOGIN)
    },
    resetForm() {
      const refs = ['name', 'username', 'email', 'content', 'type']
      this.formData = {
        Name: '',
        Username: '',
        Email: '',
        Type: '',
        Contact: ''
      }
      this.isValid = false
      this.$_validateMixin_reset(refs)
    },
  }
};
</script>

<style scoped lang="scss">
.contact {
  position: relative;
  .container {
    max-width: 1160px;
    margin: 0 auto;
    text-align: center;
  }
  &__title {
    color: #ffffff;
    font-weight: 700;
    font-size: 32px;
    line-height: 44px;
    padding-top: 64px;
    margin-bottom: 40px;
  }
  &__content {
    padding-bottom: 64px;
    max-width: 960px;
    margin: auto;
    #map {
      min-height: calc(100vh - 500px);
    }
    .help {
      .title {
        margin-top: 40px;
        font-weight: 700;
        font-size: 24px;
        line-height: 34px;
        text-align: center;
        color: #FFFFFF;
      }
      .description {
        font-weight: 400;
        font-size: 15px;
        line-height: 24px;
        text-align: center;
        color: #ADAEB2;
      }
    }
    .form-contact {
      .contact__name,
      .contact__username,
      .contact__email,
      .contact__description{
        text-align: left;
      }
      label {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #FFFFFF;
      }
      input, textarea {
        background: #20242D;
        border: none;
        border-radius: 8px;
        color: #FFFFFF;
      }
      textarea {
        resize: none;
        margin-bottom: 0;
        &.area-error {
          border: 1px solid #FF5631;
        }
      }
      .error {
        margin-top: 10px;
        color: #EA290E;
        font-size: 12px;
        line-height: 16px;
        font-weight: 500;
        text-align: left;
      }
      .btn-primary {
        margin-top: 15px;
        width: 320px;
        @media only screen and (max-width: 992px) {
          width: 200px;
        }
      }
    }
    .contact-icon {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-start;
      margin-top: 40px;
      .item {
        width: calc((100% - 10px)/4);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        &__link {
          text-decoration: none;
        }
        img {
          width: 80px;
          height: 80px;
        }
        .text {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          span {
            &:first-child {
              display: inline-block;
              margin-top: 8px;
              font-size: 16px;
              line-height: 22px;
              color: #ADAEB2;
            }
            &:last-child {
              font-size: 20px;
              line-height: 28px;
              color: #FFFFFF;
            }
          }
        }
        &:hover {
          cursor: pointer;
          filter: brightness(1.5);
        }
        @media only screen and (max-width: 992px) {
          width: calc((100% - 10px)/2);
          margin-bottom: 10px;
          .text {
            span {
              &:first-child {
                font-size: 14px;
                line-height: 22px;
              }
              &:last-child {
                font-size: 16px;
                line-height: 26px;
              }
            }
          }
        }
      }
    }
  }
  /deep/.base-select {
    .error {
      text-align: left;
      display: block;
    }
  }
}
</style>
