<template>
  <BaseModal :config="config">
    <template slot="content">
      <div class="withdraw__title">{{ $t('link-kyc.modal-title') }}</div>
      <div class="withdraw__content">
        <label class="title-select">{{ $t('link-kyc.Sponsor') }}</label>
        <v-select ref="account" v-model="formData.SponsorId" :reduce="(member) => member.ValueField"
          label="DisplayField" @search="onSearch" :options="members">
          <template #no-options>{{ $t("no-options") }}</template>
        </v-select>

        <BaseInputCustom class-name="theme-light" ref="reason" v-model="formData.Message" type="textarea" name="reason"
          :limit-input="250"
          :placeholder="$t('placeholder.max', [250])"
          required :label="$t('transfer.message')" rows="3" />
      </div>
      <div class="d-flex justify-content-around">
        <button class="btn btn-outline-primary" @click="cancel">
          {{ $t('action.cancel') }}
        </button>
        <button class="btn btn-primary" @click="submit" :disabled="disabled">
          {{ $t('action.request') }}
        </button>
      </div>
    </template>
  </BaseModal>
</template>
  
<script>
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { MODAL, NOTIFICATION_ICON } from "@/constants";
import BaseInputCustom from "@components/Inputs/BaseInputCustom";
import validateMixin from "@/core/mixins/validate";
import { RepositoryFactory } from "@apis/repositoryFactory";
const WalletRepository = RepositoryFactory.get("wallet");
import debounce from "lodash.debounce";
const UserRepository = RepositoryFactory.get("user");
const KYCRepository = RepositoryFactory.get('kyc')

export default {
  name: "ModalRequestLinkKyc",
  components: {
    BaseInputCustom,
    vSelect,
  },
  props: {
    vlinkId: {
      type: String,
      default: ''
    }
  },
  mixins: [validateMixin],
  data() {
    return {
      members: [],
      config: {
        id: MODAL.REQUEST_LINKKYC,
        class: "modal--withdraw",
        noCloseOnBackDrop: false,
        centered: true,
        theme: "light",
      },
      formData: {
        SponsorId: "",
        Message: ""
      },
      kycList: []
    };
  },
  computed: {
    disabled() {
      return (!this.formData.SponsorId || !this.formData.Message)
    }
  },
  async mounted() {
    try {
      await this.$nextTick()
      if (this.vlinkId) {
        this.fetchData(this.vlinkId)
      }
    } catch (error) {
    }
  },
  methods: {
    trigger (item) {
      if (!item) {
        this.formData = {
          SponsorId: "",
          Message: ""
        }
        return
      }
      this.fetchData(item.SponsorVLinkId)
      this.formData.Message = item.Message
    },
    async fetchData(val) {
      try {
        this.members = await UserRepository.searchUser(val);
        this.formData.SponsorId = this.members[0].ValueField;
      } catch (error) {
        console.log(error);
      }
    },
    onSearch(search, loading) {
      if (search.length) {
        loading(true);
        this.search(loading, search, this);
      }
    },
    search: debounce(async (loading, search, vm) => {
      try {
        const data = await UserRepository.searchUser(search);
        vm.members = data;
      } catch (error) {
        vm.members = [];
      } finally {
        loading(false);
      }
    }, 350),
    cancel() {
      this.formData = {
        SponsorId: "",
        Message: ""
      },
      this.$bvModal.hide(this.config.id);
    },
    async submit() {
      try {
        await KYCRepository.requestLinkKyc(this.formData)
        this.$notifyPopup(NOTIFICATION_ICON.SUCCESS,
          this.$t('swal.title.success'),
          this.$t('link-kyc.sent-success'),
          {
            action1Text: 'OK',
            action1Func: () => this.cancel(),
          },
          'dashboard'
        )
        this.$emit('refresh')
      } catch (error) {
        const res = JSON.parse(error.request.response)
        this.$notifyPopup(NOTIFICATION_ICON.ERROR,
          '',
          res.message + '',
          {
            action1Text: 'OK',
            action1Func: this.closePopup
          },
          'dashboard'
        )
        return false
      }

    },
  },
};
</script>
  
<style scoped lang="scss">
.withdraw {
  width: 568px;
  color: #ffffff;
  margin-top: 32px;

  &__title {
    font-family: "Overpass";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 34px;
    text-align: center;
    color: #141822;
  }
}

label.title-select {
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 8px;
}

::v-deep .eye {
  color: #43464e !important;
}

::v-deep .base-select {
  margin-bottom: 20px;

  &__label {
    color: #43464e;
  }
}

::v-deep .base-select__wrap {
  background: #f7f7f7;

  .arrow-down {
    color: #43464e;
  }
}

::v-deep .base-select__inner {
  p {
    color: #898b91;
  }
}

::v-deep .vs__dropdown-toggle {
  background: #f7f7f7 !important;
  color: #898b91;
}

::v-deep .base-select {
  margin-bottom: 20px;

  &__label {
    color: #43464e;
  }
}

::v-deep .base-select__wrap {
  background: #f7f7f7;

  .arrow-down {
    color: #43464e;
  }
}

::v-deep .base-select__inner {
  p {
    color: #898b91;
  }
}

/deep/ textarea {
  background: #f7f7f7;
  border-radius: 8px;
  outline: none !important;
  border: none;
  font-family: "Overpass";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #141822;
  padding: 10px;
}

/deep/ .v-select {
  margin-bottom: 20px;
}
</style>
</style>
  