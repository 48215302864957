<template>
  <div class="p-4 bg-white">
    <div class="modal-transfer__title">{{ $t("titles.transfer") }}</div>
    <div class="modal-transfer__content">
      <div class="modal-transfer__content--tabs">
        <span
          class="transfer-tab"
          @click="activeTab = 'INTERNAL'"
          :class="{ active: activeTab === 'INTERNAL' }"
          >{{ $t("titles.internal") }}</span
        >
        <span
          class="transfer-tab"
          @click="activeTab = 'EXTERNAL'"
          :class="{ active: activeTab === 'EXTERNAL' }"
          >{{ $t("titles.external") }}</span
        >
      </div>
      <div class="modal-transfer__content--content">
        <TransferInternal
          :message="message"
          :walletType="wallettype"
          :address="address"
          :amount="amount"
          :isShowCameraIcon="true"
          :isPage="true"
          :unit="unit" v-if="activeTab === 'INTERNAL'" />
        <TransferExternal :isPage="true" v-if="activeTab === 'EXTERNAL'" />
      </div>
    </div>
  </div>
</template>

<script>
import { MODAL } from "@/constants";
import globalMixin from "@/core/mixins/global";
import TransferInternal from "@/containers/Wallet/transfer-internal.vue";
import TransferExternal from "@/containers/Wallet/transfer-external.vue";

export default {
  name: "ModalTransfer",
  components: {
    TransferInternal,
    TransferExternal,
  },
  mixins: [globalMixin],
  async created() {
    const { message, wallettype, address, amount, unit } = this.$route.query;
    this.message = message
    this.wallettype = wallettype
    this.address = address
    this.amount = amount
    this.unit = unit
  },
  data() {
    return {
      config: {
        id: MODAL.TRANSFER_HOME,
        class: "modal--transfer",
        noCloseOnBackDrop: false,
        centered: true,
        theme: "light",
      },
      message: '',
      wallettype: '',
      address: '',
      amount: 0,
      unit: '',
      activeTab: "INTERNAL",
    };
  },
};
</script>

<style scoped lang="scss">
.modal-transfer {
  &__title {
    font-family: "Overpass";
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 44px;
    text-align: center;
    color: #141822;
    margin-bottom: 32px;
  }
  &__content {
    &--tabs {
      padding-bottom: 1rem;
      border-bottom: 1px solid #eaeaea;
      position: relative;
      span {
        font-size: 20px;
        line-height: 28px;
        color: #43464e;
        cursor: pointer;
        &:first-child {
          margin-right: 48px;
        }
        &.active {
          color: #007bff;
          &::before {
            content: "";
            width: 76px;
            height: 1px;
            background: #007bff;
            position: absolute;
            bottom: 0;
          }
        }
      }
    }
  }
}
</style>
