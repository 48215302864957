<template>
  <div class="body">
    <Details />
  </div>
</template>

<script>
import Details from '@/containers/News/details.vue'
export default {
  name: "details",
  components: {
    Details
  }
}
</script>

<style scoped>
.body {
  background: #141822;
}
</style>
