<template>
  <div class="network">
    <h2 class="network__title">{{ $t("menu-items.myBonuses") }}</h2>
    <div class="network__content border-radius-2">
      <div class="list">
        <div class="tabs">
          <div
            class="item"
            v-for="tab in tabs"
            :key="tab.key"
            @click="tabActive = tab"
            :class="{ active: tab.key === tabActive.key }"
          >
            <span>{{ $t(tab.name) }}</span>
          </div>
        </div>
      </div>
      <div class="tab-content">
        <component :is="tabActive.component" />
      </div>
    </div>
  </div>
</template>

<script>
import { BONUS_TABS } from "@/constants/menu";

export default {
  components: {
    pools: () => import("@containers/Network/pools.vue"),
    direct: () => import("@containers/Network/direct.vue"),
    indirect: () => import("@containers/Network/indirect.vue"),
    team: () => import("@containers/Network/team.vue"),
    "share-ap": () => import("@containers/Network/share-ap.vue"),
  },
  data() {
    return {
      tabs: BONUS_TABS,
      tabActive: BONUS_TABS[0],
    };
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.network {
  padding: 24px;
  // background: #f5f7fa;
  &__title {
    font-weight: 700;
    font-size: 24px;
    line-height: 34px;
    color: #ffffff;
    margin-bottom: 24px;
  }
  &__content {
    background: #ffffff;
    padding: 16px 24px;
    .list {
      overflow-y: hidden;
      overflow-x: auto;
      &::-webkit-scrollbar {
        opacity: 0;
        visibility: hidden;
      }
      .tabs {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border-bottom: 1px solid #eaeaea;
        .item {
          padding: 0 15px 15px 15px;
          &:first-child {
            padding-left: 0;
          }
          span {
            display: inline-block;
            font-weight: 400;
            font-size: 20px;
            line-height: 28px;
            color: #43464e;
            position: relative;
          }
          &.active,
          &:hover {
            cursor: pointer;
            span {
              color: #007bff;
              &:before {
                content: "";
                width: 100%;
                background: #007bff;
                position: absolute;
                bottom: -15px;
                left: 0;
                height: 1px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
