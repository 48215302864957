<template>
  <div class="body">
    <div class="bg-main">
      <business />
      <slider />
      <statistics />
      <shop />
      <system />
    </div>
  </div>
</template>

<script>
export default {
  components: {
    'slider': () => import('@containers/Home/slider.vue'),
    'business': () => import('@containers/Home/business.vue'),
    // 'ads': () => import('@containers/Home/ads.vue'),
    'statistics': () => import('@containers/Home/statistics.vue'),
    'shop': () => import('@containers/Home/shop.vue'),
    // 'road-map': () => import('@containers/Home/road-map.vue'),
    'system': () => import('@containers/Home/system.vue'),
    // 'about-us': () => import('@containers/Home/about-us.vue'),

  }
}
</script>

<style scoped lang="scss">
.body {
  background: #141822;
  overflow-x: hidden;
}

.bg-main {
  background: url('/assets/img/bg-main.jpeg') no-repeat;
  background-position: top;
  background-size: cover;
}
</style>
