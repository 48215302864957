<template>
  <div class="system">
    <div class="row">
      <div class="col-md-12">
        <!-- <p class="title">{{ $t('home.title.eco')}}</p>
        <p class="text">{{ $t('home.about.Speed.content')}}</p>
        <div class="system-img">
          <img
            src="/img/system.webp"
            alt="roadmap-img"
            @click="photoIndex = 0"
          >
          <a @click="openLink('https://vlinkpay.com')" class="vlinkpay"></a>
          <a @click="openLink('https://vlinkgroup.net')" class="vlinkgroup"></a>
          <a @click="openLink('https://vlinknail.com')" class="vlinknail"></a>
          <a @click="openLink('https://vlinkmart.com')" class="vlinkmart"></a>
          <LightGallery
            :images="['/img/system.webp']"
            :index="photoIndex"
            :disable-scroll="true"
            @close="photoIndex = null"
          />
        </div> -->
        <div class="col-12 text-center align-items-center d-flex w-100">
        <div class="line-gradient" />
        <span class="title mt-0 text-gradient font-weight-normal text-nowrap px-3">{{ $t("home.title.develop") }}</span>
        <div class="line-gradient" />
      </div>
        <p class="text">{{ $t('home.descriptionDev') }}</p>
        <div class="system-video">
          <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6 item">
              <iframe
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/J39j18ApF2s"
                title="YouTube video player" frameborder="0"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6 item">
              <iframe
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/-YDKnEWuyvA"
                title="YouTube video player" frameborder="0"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { LightGallery } from "vue-light-gallery";
export default {
  components: {
    LightGallery
  },
  data () {
    return {
      photoIndex: null
    }
  },
  methods: {
    openLink(link) {
      window.open(link);
    }
  }
}
</script>
<style scoped lang="scss">
.system {
  background: url("http://localhost:8080/img/bg-system.webp") no-repeat center;
  background-size: cover;
  .row {
    max-width: 1160px;
    margin: 0 auto;
  }
  .title {
    font-weight: 700;
    font-size: 32px;
    line-height: 44px;
    text-align: center;
    color: #FFFFFF;
    margin-top: 64px;
  }
  .text {
    margin-top: 40px;
    margin-bottom: 32px;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #ADAEB2;
    text-align: center;
  }
  .system-img {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    img {
      width: 100%;
      height: 586px;
      @media only screen and (max-width: 992px) {
        height: auto;
      }
    }
    .vlinkpay {
      position: absolute;
      top: 5px;
      left: 322px;
      width: 50px;
      height: 50px;
      cursor: pointer;
      @media only screen and (max-width: 1100px) {
        top: 1%;
        left: 27%;
        width: 8vw;
        height: 8vw;
      }
    }
    .vlinkgroup {
      position: absolute;
      top: 58px;
      width: 102px;
      height: 80px;
      left: 50%;
      transform: translateX(-52%);
      cursor: pointer;
      @media only screen and (max-width: 1100px) {
        top: 10%;
        width: 13.333vw;
        height: 10.667vw;
      }
    }
    .vlinkmart {
      position: absolute;
      bottom: 185px;
      right: 372px;
      width: 50px;
      height: 50px;
      cursor: pointer;
      @media only screen and (max-width: 1100px) {
        bottom: 29%;
        right: 32%;
        width: 8vw;
      height: 8vw;
      }
    }
    .vlinknail {
      position: absolute;
      top: 9px;
      right: 313px;
      width: 50px;
      height: 50px;
      cursor: pointer;
      @media only screen and (max-width: 1100px) {
        top: 1%;
        right: 27%;
        width: px2vw(30px);
      height: px2vw(30px);
      }
    }
  }
  .system-video {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 64px;
    .item {
      iframe {
        border-radius: 8px;
        border: 2px solid #a4793a;
        width: 480px;
        height: 250px;
        @media only screen and (max-width: 768px) {
          width: 100%;
          height: 228px;
        }
      }
      @media only screen and (max-width: 768px) {
        &:first-child {
          margin-bottom: 16px;
        }
      }
    }

  }
}
</style>
