<template>
  <div class="convert">
    <div class="convert__title">{{ $t('swap.title') }}</div>
    <div class="convert__content">
      <BaseSelect class="theme-light" ref="from" :input-value="from" name="from" :label="$t('swap.from')"
        :items="fromWallet" :is-show-default="false" required key-loop="index" @change="from = $event" />

      <BaseSelect class="theme-light" ref="to" :input-value="to" name="to" :label="$t('swap.to')" :items="toWallet"
        :is-show-default="false" required key-loop="index" @change="to = $event" />

      <BaseInputCustomWallet class-name="theme-light" ref="amount" v-model="amount" type="text" name="amount" required
        placeholder="0.00" :label="$t('fields.Amount')" thousands-separator-symbol=","
        :balance="$options.filters.formatNumber(currentValueWallet)"
        :maxValue="$options.filters.formatNumber(currentValueWallet)" @input="debounceInput"
        :error-required="$t('validate.required', [$t('fields.Amount')])" />

      <BaseInputCustom class-name="theme-light" ref="pin" v-model="Pin" is-pin name="pin" inputmode="numeric" required
        :error-required="$t('validate.required', ['pin'])" label="PIN" />
    </div>
    <p v-if="receive > 0 && amount" class="text-note-2">{{ $t("swap.receive",
      [$options.filters.toCurrency($options.filters.formatValueCurrency(amount)), from,
      $options.filters.toCurrency(receive), to])
    }}</p>
    <p class="text-note-2">{{ $t("payments.methods.fee") }}:

      <template v-if="fetchedFee"> {{ fee | toCurrency }} {{ from }} </template>
      <template v-else><span class="spinner-border spinner-border-sm" /></template>

      <a class="ml-1" href="/faq" target="_blank"><svg width="24" height="24" viewBox="0 0 24 24" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M12 2C6.47301 2 2 6.4725 2 12C2 17.5269 6.4725 22 12 22C17.527 22 22 17.5275 22 12C22 6.47309 17.5275 2 12 2ZM12 20.6046C7.25539 20.6046 3.39535 16.7446 3.39535 12C3.39535 7.25535 7.25539 3.39535 12 3.39535C16.7446 3.39535 20.6046 7.25535 20.6046 12C20.6046 16.7446 16.7446 20.6046 12 20.6046Z"
            fill="#ADAEB2" />
          <path
            d="M12.0018 10.3359C11.4095 10.3359 10.9883 10.5861 10.9883 10.9546V15.9697C10.9883 16.2857 11.4095 16.6015 12.0018 16.6015C12.5679 16.6015 13.0285 16.2857 13.0285 15.9697V10.9546C13.0285 10.5861 12.5679 10.3359 12.0018 10.3359Z"
            fill="#ADAEB2" />
          <path
            d="M12.0012 7.24219C11.3957 7.24219 10.9219 7.67656 10.9219 8.17676C10.9219 8.67699 11.3957 9.12453 12.0012 9.12453C12.5936 9.12453 13.0675 8.67699 13.0675 8.17676C13.0675 7.67656 12.5935 7.24219 12.0012 7.24219Z"
            fill="#ADAEB2" />
        </svg>
      </a>
    </p>
    <p class="text-note">
      {{ $t("stock.pin.first") }}
      <router-link tag="a" :to="'/profile/security?tab=pin'" target="_blank" style="color: #007bff; font-weight: bold">
        {{ $t("stock.pin.second") }}
      </router-link>
      {{ $t("stock.pin.third") }}
    </p>
    <template v-if="!isSwapVmmToken">
      <p class="text-note">{{ $t("BusinessSwap2") }}</p>
      <p class="text-note">{{ $t("BusinessSwap") }}</p>
    </template>
    <div class="d-flex">
      <button class="btn w-100 btn-primary" @click="submit" :class="{ 'button--loading': loading }">
        <span>{{ $t('action.swap') }}</span>
      </button>
    </div>
  </div>
</template>

<script>
import { NOTIFICATION_ICON } from "@/constants";
import BaseInputCustomWallet from "@components/Inputs/BaseInputCustomWallet";
import BaseInputCustom from "@components/Inputs/BaseInputCustom";
import validateMixin from "@/core/mixins/validate";
import { RepositoryFactory } from "@apis/repositoryFactory";
import BaseSelect from "@/components/Inputs/BaseSelect.vue";
const WalletRepository = RepositoryFactory.get("wallet");
import { mapGetters } from "vuex";
import debounce from 'lodash.debounce'

export default {
  components: {
    BaseInputCustom,
    BaseInputCustomWallet,
    BaseSelect,
  },
  mixins: [validateMixin],
  data() {
    return {
      from: 'VLG',
      to: 'VUSD',
      Pin: '',
      fromWallet: ['VLG', 'VMM Token Release'],
      toWallet: ['VUSD', 'VMM Token'],
      amount: null,
      fee: 0,
      receive: 0,
      loading: false,
      fetchedFee: true,
      currentValueWallet: 0
    };
  },
  computed: {
    ...mapGetters({
      walletValue: "getWallet",
    }),
    isSwapVmmToken() {
      return this.to == 'VMM Token'
    }
  },
  watch: {
    to(value) {
      this.from = value == 'VUSD' ? 'VLG' : 'VMM Token Release'
    },
    from(value) {
      this.to = value == 'VLG' ? 'VUSD' : 'VMM Token'
      this.currentValueWallet = value == 'VLG' ? this.walletValue.VLGTokenAvailable.Amount : this.walletValue.VmmRelease.Amount
    },
  },
  mounted() {
    this.currentValueWallet = this.from == 'VLG' ? this.walletValue.VLGTokenAvailable.Amount : this.walletValue.VmmRelease.Amount
  },
  methods: {
    debounceInput: debounce(function (e) {
      this.checkFee()
    }, 500),
    async checkFee() {
      try {
        this.fetchedFee = false
        const refs = ["amount", "from", "to"];
        const refsValid = this.$_validateMixin_refs(refs);
        if (refsValid) {
          const data = {
            fromWallet: this.from,
            toWallet: this.to,
            amount: this.amount || 0,
          }
          const response = await WalletRepository.checkFeeSwap(data);
          this.fee = response.Fee
          this.receive = this.isSwapVmmToken ? this.amount :
            this.$options.filters.formatValueCurrency(this.amount) * Number(this.walletValue.VLGTokenAvailable.CurrentPrice)
        }
      } catch (error) {
        this.$alertMsg('fail', error.response.data.message)
      } finally {
        this.fetchedFee = true
      }
    },
    async submit() {
      const refs = ["amount", "pin", "from", "to"];
      const refsValid = this.$_validateMixin_refs(refs);
      if (refsValid) {
        this.loading = true;
        try {
          const data = {
            FromWallet: this.from,
            ToWallet: this.to,
            Amount: this.amount,
            Pin: this.Pin
          }
          const response = await WalletRepository.swap(data);
          this.$notifyPopup(
            NOTIFICATION_ICON.SUCCESS,
            this.$t("swal.title.success"),
            '',
            {
              action1Text: "OK",
              action1Func: () => ({}),
            }
          );
          this.$emit("refresh")
        } catch (error) {
          this.$notifyPopup(
            NOTIFICATION_ICON.ERROR,
            this.$t("swal.title.fail"),
            error.response.data.message,
            {
              action1Text: this.$t('action.try'),
              action1Func: () => ({}),
            }
          );
        }
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.convert {
  &__title {
    font-family: "Overpass";
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 44px;
    text-align: center;
    color: #141822;
    margin-bottom: 32px;
  }

  &__password {
    margin-top: 15px;
  }

  &__btn {
    height: 40px;
  }

  &__action {
    display: grid;
    grid-template-columns: 47% 47%;
    justify-content: space-between;
  }

  &__btn-cancel {
    color: #007bff;
    background: #ffffff;
    border: 1px solid #007bff;

    &:hover {
      background: #007bff;
      color: #ffffff;
    }
  }

  .text-note {
    font-size: 14px;
    line-height: 20px;
    color: #898b91;
  }
}

::v-deep .eye {
  color: #43464e !important;
}

::v-deep .base-select {
  margin-bottom: 20px;

  &__label {
    color: #43464e;
  }
}

::v-deep .base-select__wrap {
  background: #f7f7f7;

  .arrow-down {
    color: #43464e;
  }
}

::v-deep .base-select__inner {
  p {
    color: #898b91;
  }
}
</style>
