<template>
  <div class="body">
    <terms />
  </div>
</template>

<script>
export default {
  components: {
    terms: () => import("@containers/Terms/index.vue"),
  },
};
</script>

<style scoped lang="scss">
.body {
  background: #141822;
}
</style>
