<template>
  <Wallet />
</template>

<script>
export default {
  components: {
    Wallet: () => import("@containers/Wallet/index.vue"),
  },
};
</script>

<style>
</style>
