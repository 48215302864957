<template>
  <div class="description">
    <div class="container">
      <h2 class="compensation-title">
        {{ $t("docs.title") }}
      </h2>
      <span class="header-separator d-block m-auto"></span>
      <h2 class="description__title mt-3">{{ $t("docs.title1") }}</h2>
      <p class="description__text">
        {{ $t("docs.decs1") }}
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
.description {
  background: #20242d;
  position: relative;
  @media only screen and (max-width: 768px) {
    padding: 0 12px;
  }
  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    background-image: url("/img/compensation/description-bg.png");
    width: 100%;
    height: 100%;
    opacity: 0.2;
    mix-blend-mode: luminosity;
  }
  .container {
    padding: 64px 0;
    text-align: center;
  }
  .compensation-title {
    color: #ffffff;
    font-family: "Overpass";
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 44px;
  }
  .header-separator {
    width: 200px;
    height: 3px;
    background: linear-gradient(
      90deg,
      #a9782b,
      #f1d47d 14%,
      #f8f7d7 25%,
      #f1d47d 36%,
      #a9782b 49%,
      #f1d47d 61%,
      #f8f7d7 73%,
      #f1d47d 83%,
      #a9782b
    );
  }
  &__title {
    color: #ffffff;
    font-family: "Overpass";
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 44px;
  }
  &__text {
    font-family: "Overpass";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #adaeb2;
  }
}
</style>
