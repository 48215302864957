import { render, staticRenderFns } from "./description.vue?vue&type=template&id=37f2bcd4&scoped=true&"
import script from "./description.vue?vue&type=script&lang=js&"
export * from "./description.vue?vue&type=script&lang=js&"
import style0 from "./description.vue?vue&type=style&index=0&id=37f2bcd4&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37f2bcd4",
  null
  
)

export default component.exports